import type { Pow } from '../utils/types';
import { TbBed, TbClock } from 'react-icons/tb';
import buildingIcon from 'assets/buildingType.svg';

interface Props {
  template: Pow;
  // selectedProgram: Pow | null;
  // handleSelect: (template: Pow) => void;
}

const PowTemplateCard = ({ template }: Props) => {
  // selectedProgram, handleSelect
  const { name, tasks, buildingType, numOfRooms } = template;
  const totalSubstasks = tasks.reduce((acc, task) => acc + task.subTasks.length, 0);
  const totalDuration = tasks.reduce((acc, task) => {
    const duration = task.subTasks.reduce((acc, subtask) => acc + subtask.duration, 0);
    return acc + duration;
  }, 0);

  return (
    <>
      <div
        // onClick={() => handleSelect(template)}
        // className={`flex flex-col gap-4 px-4 py-6  rounded-md border  cursor-pointer hover:bg-bbg ${
        //   selectedProgram === template ? 'border-bblue bg-bbg' : 'border-ashShade-3 bg-white'
        // } `}
        className="flex flex-col gap-4 px-4 py-6  rounded-md border  cursor-pointer hover:bg-bbg border-ashShade-3 bg-white">
        <p className="font-semibold">{name}</p>
        <div className="flex gap-1 items-center text-bash">
          <span className="flex items-center gap-1 text-bash">
            <TbBed color="#77828D" size={16} />
            <p className="text-sm">{numOfRooms} Bedroom</p>
          </span>
          &bull;
          <span className="flex items-center gap-1 text-bash">
            <img src={buildingIcon} alt="duplex" className="w-4 h-4 text-bash" />
            <p className="text-bash text-sm capitalize">{buildingType}</p>
          </span>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="flex gap-2 items-center text-xs">
            <span className="bg-bbg text-bblue py-1 px-2 rounded-3xl">{tasks.length} Tasks</span>
            <span className="bg-[#E9F6F2] text-[#26A87D] py-1 px-2 rounded-3xl">
              {totalSubstasks} Subtasks
            </span>
          </div>
          <span className="flex items-center gap-1 text-bash text-xs">
            <TbClock color="#77828D" size={16} />
            {totalDuration} days
          </span>
        </div>
      </div>
    </>
  );
};
export default PowTemplateCard;
