import { useRef, useContext } from 'react';
import { TbDownload, TbPlus } from 'react-icons/tb';
import { hoverFade } from 'constants/globalStyles';
import { useClickOutSideComponent } from 'components/projects/Team/Views/Components/OnScreen';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from 'context';
import { PMStoreContext } from '../../Context';
import type { POW } from '../../types';

interface Props {
  setOptions: React.Dispatch<React.SetStateAction<boolean>>;
  toggleModal: (ModalId?: number) => void;
  pow: POW;
}

const NewTaskOptions = ({ setOptions, toggleModal, pow }: Props) => {
  const navigate = useNavigate();
  const { selectedProjectIndex, data } = useContext(StoreContext);
  const optionsRef = useRef<any>();
  useClickOutSideComponent(optionsRef, () => {
    setOptions(false);
  });
  const projectId = data[selectedProjectIndex]._id;
  const { handleTaskImporting } = useContext(PMStoreContext);

  return (
    <div ref={optionsRef} className="absolute flex flex-col bg-white top-10 w-[154px] z-50">
      <button
        className={
          'flex items-center group gap-2 text-bash text-sm hover:bg-pbg font-Medium p-2' + hoverFade
        }
        onClick={() => toggleModal()}>
        <span>
          <TbPlus size={16} />
        </span>
        <span>New Task</span>
      </button>
      <button
        onClick={() => {
          handleTaskImporting(pow);
          navigate(`/projects/${projectId}/management/import`);
        }}
        className={
          'flex gap-2 items-center group text-bblue text-sm font-Medium hover:bg-bbg p-2' +
          hoverFade
        }>
        <span>
          <TbDownload />
        </span>
        <span>Import Task</span>
      </button>
    </div>
  );
};
export default NewTaskOptions;
