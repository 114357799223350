import { FC, useCallback, useEffect, useState } from 'react';
import Button from 'components/shared/Button';
import SuperModal from 'components/shared/SuperModal';
import { CgClose } from 'react-icons/cg';
import { PiWarningCircleFill } from 'react-icons/pi';
import { hoverFade } from 'constants/globalStyles';
import { useParams } from 'react-router-dom';
import { postForm } from 'apis/postForm';
import { displayError, displaySuccess } from 'Utils';
import useProcurement from '../../layout/useProcurement';
import { useAppDispatch } from 'store/hooks';
import { updateProcurement } from 'store/slices/procurementSlice';

interface Props {
  onClose: () => void;
}

export const ResetThresholdModal: FC<Props> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  let { procurementId } = useParams();
  const dispatch = useAppDispatch();

  const handleResetThreshold = useCallback(async () => {
    setIsLoading(true);

    const payload = {
      dashboardId: procurementId
    };

    const { e, response } = await postForm(
      'patch',
      `procurements/dashboard/reset-threshold`,
      payload
    );
    if (response) {
      displaySuccess('Procurements restored successfully');
      dispatch(updateProcurement(response.data?.data));
      onClose();
    } else {
      displayError(e?.message || '');
    }

    setIsLoading(false);
  }, [procurementId, dispatch, onClose]);

  return (
    <SuperModal
      classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={onClose}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white rounded-md py-6 mt-20 mb-10 w-1/2 max-w-[450px] flex flex-col gap-4 ">
        <div className="flex items-center justify-between px-6">
          <div className="flex items-center gap-2">
            <span>
              <PiWarningCircleFill className="text-bblue" fontSize={20} />
            </span>
            <p className="text-2xl font-medium font-satoshi text-bblack-1">Reset threshold</p>
          </div>

          <span className={hoverFade + ' text-sm text-bash'} onClick={onClose}>
            <CgClose fontSize={24} color="#9099A8" />
          </span>
        </div>

        <div className="border border-pbg  text-bash text-lg font-normal px-6 py-6">
          <p>Are you sure you want to reset threshold for all materials?</p>
          <p>You cannot undo this.</p>
        </div>

        <div className=" flex justify-end gap-x-4 px-6">
          <Button onClick={onClose} type="secondary" text="Cancel" />
          <Button isLoading={isLoading} onClick={handleResetThreshold} text="Reset threshold" />
        </div>
      </div>
    </SuperModal>
  );
};
