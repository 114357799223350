import { useRef, useState } from 'react';
import { TbPlus, TbMinus } from 'react-icons/tb';
import { useClickOutSideComponent } from 'components/projects/Team/Views/Components/OnScreen';

interface Props {
  setActivity: React.Dispatch<React.SetStateAction<'disburse' | 'return'>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowActivitySelector: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActivitySelector = ({ setActivity, setShowModal, setShowActivitySelector }: Props) => {
  const [loading, setLoading] = useState(false);

  const activityRef = useRef<any>();
  useClickOutSideComponent(activityRef, () => {
    if (!loading) {
      setShowActivitySelector(false);
    }
  });

  return (
    <div
      className="absolute top-11 right-0 flex flex-col gap-2 w-32 rounded-md bg-white border border-pbg"
      ref={activityRef}>
      <button
        className="flex gap-2 items-center text-redShades-2 p-2 hover:bg-redShades-1 text-sm"
        onClick={() => {
          setActivity('disburse');
          setShowModal(true);
        }}>
        <TbMinus />
        <span>Disburse</span>
      </button>
      <button
        className="flex gap-2 items-center text-bgreen-0 p-2 hover:bg-bgreen-1 text-sm"
        onClick={() => {
          setActivity('return');
          setShowModal(true);
        }}>
        <TbPlus />
        <span>Return</span>
      </button>
    </div>
  );
};
export default ActivitySelector;
