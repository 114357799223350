import { FC, RefObject, useCallback, useRef, useState } from 'react';
import { useClickOutSideComponent } from 'components/projects/Team/Views/Components/OnScreen';
import { TheOption } from 'pages/projects/Home/Components/ProjectCard';
import { TbLineHeight, TbStar, TbStarOff } from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { DashBoardItem } from '../../layout/types';
import { addFavorite, removeFavorite } from 'store/slices/procurementFavoriteSlice';
import { ThresholdModal } from './ThresholdModal';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  item: DashBoardItem;
}

export const DashboardOptionsMenu: FC<Props> = ({ isVisible, onClose, item }) => {
  const optionsRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [showThresholdModal, setShowThresholdModal] = useState(false);

  const { data } = useAppSelector((m) => m.procurementFavorite);
  const isFavorite = data.some((favItem) => favItem._id === item._id);

  const handleToggleFavorite = useCallback(() => {
    if (isFavorite) {
      dispatch(removeFavorite(item._id));
    } else {
      dispatch(addFavorite(item));
    }
    onClose();
  }, [dispatch, isFavorite, item, onClose]);

  useClickOutSideComponent(optionsRef, onClose);

  return (
    <div ref={optionsRef}>
      {isVisible && (
        <div className="top-0 z-10 right-2 absolute bg-white rounded-md shadow-bnkle lg:w-[200px] ">
          <TheOption
            icon={TbLineHeight}
            text="Set threshold"
            onClick={() => setShowThresholdModal(true)}
            className=" px-4"
          />
          <TheOption
            icon={isFavorite ? TbStarOff : TbStar}
            text={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
            onClick={handleToggleFavorite}
            className={`px-4 text-sm ${isFavorite ? 'text-redShades-2 hover:bg-redShades-1' : ''}`}
            iconColor={isFavorite ? '#B63434' : undefined}
            iconHoveredColor={isFavorite ? '#B63434' : undefined}
          />
        </div>
      )}
      {showThresholdModal && <ThresholdModal closer={onClose} item={item} />}
    </div>
  );
};
