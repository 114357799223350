import Button from 'components/shared/Button';
import React from 'react';
import { BsFunnel } from 'react-icons/bs';
import { TbAccessible, TbPlus } from 'react-icons/tb';

type Fn = () => void | Promise<void>;
interface ButtonOptionsType {
  showFiler?: boolean;
  onClickFilter?: Fn;
  onClickAccess?: Fn;
  onClickAdd?: Fn;
}
const ButtonOptions = ({ onClickFilter, onClickAccess, onClickAdd }: ButtonOptionsType) => {
  return (
    <div className="flex gap-2 pb-2">
      <Button
        className="border-ashShade-2 border px-0 mx-0"
        text=""
        type="plain"
        style={{
          padding: '9px 18px'
        }}
        onClick={() => {
          if (onClickFilter) onClickFilter();
        }}
        LeftIcon={<BsFunnel color="#9099A8" />}
      />
      <Button
        onClick={() => {
          if (onClickAccess) onClickAccess();
        }}
        className="border-ashShade-2 border px-0 mx-0"
        text=""
        type="plain"
        style={{
          padding: '9px 18px'
        }}
        LeftIcon={<TbAccessible color="#9099A8" />}
      />
      <Button
        onClick={() => {
          if (onClickAdd) onClickAdd();
        }}
        text=""
        style={{
          padding: '9px 18px'
        }}
        LeftIcon={<TbPlus color="white" />}
      />
    </div>
  );
};

export default ButtonOptions;
