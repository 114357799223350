import React, { useMemo } from 'react';
import { flexer, hoverFade } from 'constants/globalStyles';
import { StoreContext } from 'context';
import { useContext } from 'react';
import { NavLink, Outlet, useLocation, useParams } from 'react-router-dom';
import { tabs } from './constants';
import TopProcurementTabs from '../components/TopProcurementTabs';

interface ProcurementTabsProps {
  buttons?: React.ReactElement;
}

const ProcurementTabs = ({ buttons }: ProcurementTabsProps) => {
  const { data, selectedProjectIndex } = useContext(StoreContext);

  const { pathname } = useLocation();

  let active = useMemo(() => {
    let _paths = pathname.split('/');
    return _paths[_paths.length - 1];
  }, [pathname]);

  let { projectId, procurementId } = useParams();

  return (
    <div className=" w-full h-full  flex flex-col  ">
      <div className=" bg-white px-6 rounded-b-lg ">
        <div className="flex w-full items-center pt-6 bg-white justify-between border-b mb-8  border-b-ashShade-4">
          <div className="flex gap-x-6 ">
            {tabs.map((m) => (
              <NavLink
                key={m.path}
                to={`/projects/${projectId}/procurement/${procurementId}/${m.path}`}>
                <div className="flex flex-col  items-center">
                  <span
                    className={` ${
                      active === m.path && 'text-blue-500 '
                    } pb-5 text-base ${hoverFade}`}>
                    {m.name}
                  </span>
                  <div
                    className={`h-1 w-7 rounded-t-md ${
                      active === m.path ? 'bg-bblue' : ''
                    } `}></div>
                </div>
              </NavLink>
            ))}
          </div>
          {buttons}
        </div>
      </div>
    </div>
  );
};

export default ProcurementTabs;
