import { FC, useState } from 'react';

import { FaEllipsisVertical } from 'react-icons/fa6';
import { formatWithComma } from 'Utils';
import { DashBoardItem } from '../../layout/types';
import { DashboardOptionsMenu } from './DashboardOptionMenu';
import { TbLineHeight } from 'react-icons/tb';
import { hoverFade } from 'constants/globalStyles';

interface Props {
  item: DashBoardItem;
  s_n: number;
  isLastItem: boolean;
  onClick: () => void;
}

export const DashboardTableItem: FC<Props> = ({ s_n, item, isLastItem, onClick }) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <tr
      className={`py-4 font-satoshi font-medium text-sm w-full ${!isLastItem ? 'border-b' : ''} ${
        item.inStock < item.threshold ? 'bg-redShades-1 text-redShades-2' : 'bg-white text-bash'
      }`}
      onClick={onClick}>
      <td className="pl-5">{s_n}</td>
      <td className="">{item.material}</td>
      <td className="">
        {formatWithComma(item.budget)} {item.unit}
      </td>
      <td className="">
        {formatWithComma(item.purchase)} {item.unit}
      </td>
      <td className="">
        <div className="flex items-center gap-1">
          {formatWithComma(item.inStock)} {item.unit}
          {item.threshold > 0 && (
            <div
              className={
                hoverFade +
                `relative flex justify-center items-center p-1 rounded-[4px] ${
                  item.inStock < item.threshold
                    ? 'bg-redShades-1 text-redShades-2'
                    : 'bg-bbg text-bblue '
                }`
              }
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}>
              <TbLineHeight size={16} />
              {showTooltip && (
                <div
                  className={`absolute flex gap-1 top-full left-0 mt-1 p-2 bg-bblack-2 shadow-lg rounded-md border border-ashShade-3 z-50 font-satoshi text-sm whitespace-nowrap`}
                  style={{ zIndex: 9999 }}>
                  <p className="font-medium text-bidsbg"> Threshold: </p>
                  <p className="text-white font-semibold">{formatWithComma(item.threshold)} Bags</p>
                </div>
              )}
            </div>
          )}
        </div>
      </td>
      <td className="py-2 relative">
        <div>
          <span
            onClick={(event) => {
              event.stopPropagation();
              setOptionsVisible(true);
            }}
            className={hoverFade + ' p-2 w-8 h-8 flex rounded-full  hover:bg-ashShade-0'}>
            <FaEllipsisVertical />
          </span>
          {optionsVisible && (
            <DashboardOptionsMenu
              onClose={() => setOptionsVisible(false)}
              isVisible={optionsVisible}
              item={item}
            />
          )}
        </div>
      </td>
    </tr>
  );
};
