import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Material, Book } from './types';
import SuperModal from 'components/shared/SuperModal';
import InputField from 'components/shared/InputField';
import Button from 'components/shared/Button';
import { DatePicker } from 'components/shared/DatePicker';
import SelectDate from 'components/shared/SelectDate';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addRecord, updateRecord } from 'store/slices/bookKeepingSlice';
import * as yup from 'yup';
import { StoreContext } from 'context';
import { postForm } from 'apis/postForm';
import { convertToNumber, displayError, displaySuccess, formatWithComma } from 'Utils';
import { TbMinus, TbPlus } from 'react-icons/tb';
import { debounce } from 'lodash';

interface Props {
  closer: () => void;
  isEditing?: boolean;
  value?: Book;
}

const initialValue = {
  name: '',
  description: ''
};

const convertRecord = (val: Book) => {
  let newVal: { [key: string]: any } = {};
  newVal.name = val.name.toString();
  newVal.description = val.description.toString();
  return newVal as Book;
};

const CreateBook = ({ closer, isEditing, value }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { selectedData } = useContext(StoreContext);
  let { errors, values, setFieldValue, handleChange, handleSubmit, touched, resetForm } = useFormik(
    {
      initialValues: isEditing && value ? convertRecord(value) : initialValue,
      onSubmit: (data) => {
        let _data: Book & { _id?: string } = { ...data } as Book;
        if (!_data?._id) {
          _data._id = Math.random().toString();
        }
        if (isEditing) {
          _edit(_data);
        } else _submit(_data);
      },
      validationSchema: yup.object({
        name: yup.string().required(),
        description: yup.string().required()
      }),
      validateOnBlur: true
    }
  );

  const _submit = async (data: Omit<Book, '_id'> & { _id?: string }) => {
    setLoading(true);
    let _id = data._id;
    if (!isEditing) {
      delete data?._id;
    }
    const { e, response } = await postForm('post', `financials/bookkeeping/add`, {
      ...data,
      project: selectedData?._id
    });
    if (response) {
      displaySuccess('Record added successfully');
      dispatch(addRecord(response.data.data));
      resetForm();
      closer();
    } else displayError(e?.message || '');

    setLoading(false);
  };

  const _edit = async (data: Omit<Book, '_id'> & { _id?: string }) => {
    setLoading(true);
    let _data: any = { name: data.name, description: data.description };

    const { e, response } = await postForm('patch', `financials/bookkeeping/update`, {
      ..._data,
      bookId: value?._id
    });
    if (response) {
      displaySuccess('Record updated successfully');
      dispatch(updateRecord(response.data.data));
      resetForm();
      closer();
    } else displayError(e?.message || '');

    setLoading(false);
  };

  return (
    <SuperModal
      classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={closer}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white rounded-md p-6 mt-20 w-1/2 max-w-[500px] ">
        <div className="flex items-center justify-between">
          <p className=" text-xl font-semibold">{isEditing ? 'Edit Book' : 'Create Book'}</p>

          <span className=" cursor-pointer text-sm text-bash" onClick={closer}>
            Close
          </span>
        </div>

        <InputField
          error={(touched?.name && errors?.name) || ''}
          name="name"
          value={values?.name}
          onChange={handleChange('name')}
          type="text"
          label="Name"
          placeholder="e.g Book"
          className=" !text-bblack-1 "
        />
        <InputField
          error={(touched.description && errors.description) || ''}
          name="description"
          value={values.description}
          onChange={handleChange('description')}
          type="text"
          label="Description"
          placeholder="e.g For Ceiling Joists"
          className=" !text-bblack-1 "
        />

        <div className=" flex justify-end gap-x-4">
          <Button onClick={closer} type="secondary" text="Cancel" />
          <Button
            isLoading={loading}
            onClick={() => {
              handleSubmit();
            }}
            text={isEditing ? 'Save Changes' : 'Create Book'}
          />
        </div>
      </div>
    </SuperModal>
  );
};

export default CreateBook;
