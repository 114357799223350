import { displayError } from 'Utils';
import { postForm } from 'apis/postForm';
import { useProcurementDetails } from 'components/projects/procurement/layout/useProcurement';
import { StoreContext } from 'context';
import React, { useContext, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { loadRecords } from 'store/slices/supplySlice';

const useSupply = (wait: boolean = false) => {
  let { selectedProject } = useContext(StoreContext);
  const [data, setData] = useState<any[]>([]);
  const [supply, setSupply] = useState<any[]>([]);
  const [supplyId, setSupplyId] = useState<string | null>(null);
  let { procurementItems } = useProcurementDetails();

  let dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(loadRecords({ activeSupply: procurementItems?.supplyId }));
  }, [procurementItems]);

  const getRecords = async () => {
    dispatch(loadRecords({ loading: true }));
    let { e, response } = await postForm(
      'get',
      `procurements/supply/list?projectId=${selectedProject?._id}`
    );

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch(loadRecords({ data, loading: false }));
    } else {
      dispatch(loadRecords({ loading: false }));
      displayError(e?.message || 'Could not fetch records');
    }
  };

  const getSupply = async (supplyId: string) => {
    if (!supplyId || supplyId.length !== 24) {
      displayError('Invalid Supply ID.');
      return;
    }
    let { e, response } = await postForm('get', `procurements/supply/find?supplyId=${supplyId}`);

    if (response) {
      setSupply(response.data?.data?.supplys);
    } else {
      console.error('Error fetching supply:', e);
      displayError(e?.message || 'Could not fetch supply materials');
    }
  };

  useEffect(() => {
    if (!wait) {
      getRecords();
    }
  }, [wait, selectedProject?._id]);

  useEffect(() => {
    if (supplyId) {
      getSupply(supplyId);
    }
    // else {
    //   displayError('No valid supply ID found.');
    // }
  }, [supplyId]);

  return {
    getRecords,
    getSupply,
    supply,
    setSupplyId
  };
};

export default useSupply;
