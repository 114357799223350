import { postForm } from 'apis/postForm';
import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateRecord } from 'store/slices/supplySlice';
import { displayError } from 'Utils';
import { Access } from './types';
import AccessComponent from 'components/projects/documents/document-repo/AccessComponent';

interface SupplyAccessModalProps {
  _id: string;
  closer: () => void;
}

const SupplyAccessModal = ({ _id, closer }: SupplyAccessModalProps) => {
  let { data } = useAppSelector((m) => m.supply);
  let { data: _members, loading } = useAppSelector((m) => m.team);
  let user = useAppSelector((m) => m.user);
  let dispatch = useAppDispatch();

  const current = useMemo(() => {
    return data.find((m) => m._id === _id);
  }, [data]);

  let selectedMembers = useMemo(() => {
    let ownerId = '';
    let isCoOwner = false;

    let list =
      current?.access.map((m) => {
        if (m.roleType === 'owner') ownerId = m.userId;
        if (m.roleType === 'co-owner' && m.userId === user._id) {
          isCoOwner = true;
        }
        return m.userId;
      }) || [];
    let isDisplay = ownerId !== user._id && !isCoOwner;
    return { list, ownerId, isDisplay, isCoOwner };
  }, [current]);

  let Members = useMemo(() => {
    if (!loading) {
      let all = Object.values(_members) || [];
      return all.filter((m) => m?._id && m?.name?.toLowerCase() !== 'general');
    }
    return [];
  }, [_members, loading, current]);

  const handleAccessGrant = async (user: Record<string, any>) => {
    const payLoad = {
      supplyId: current?._id,
      access: [
        {
          userId: user?._id,
          role: user?.role,
          roleType: 'member',
          accessType: 'viewer'
        }
      ]
    };

    let { e, response } = await postForm('patch', 'procurements/supply/add-access', payLoad);

    if (e) {
      displayError(e?.message);
    } else {
      dispatch(updateRecord(response.data.data));
    }
  };

  const onRemoveAccess = async (user: Record<string, any>) => {
    const payLoad = {
      supplyId: current?._id,
      userId: user?._id
    };

    let { e, response } = await postForm('patch', 'procurements/supply/remove-access', payLoad);

    if (e) {
      displayError(e?.message);
    } else {
      dispatch(updateRecord(response.data.data[0]));
    }
  };

  const removeAccessWithUserId = async (id: string) => {
    const payLoad = {
      supplyId: current?._id,
      userId: id
    };

    let { e, response } = await postForm('patch', 'procurements/supply/remove-access', payLoad);

    if (e) {
      displayError(e?.message);
    } else {
      dispatch(updateRecord(response.data.data[0]));
    }
  };

  const handleUpdateAccess =
    (type: 'role' | 'access') => async (user: Record<string, any>, val: string) => {
      let userAccess = current?.access?.find((m) => m.userId === user._id);
      if (userAccess) {
        let data = {
          supplyId: current?._id,
          ...(userAccess as Partial<Access>)
        };
        const isCoOwner = val === 'co-owner';

        if (isCoOwner) {
          data.roleType = 'co-owner';
          data.accessType = 'editor';
        } else {
          data.roleType = 'member';
          data.accessType = val;
        }

        delete data?._id;
        delete data?.role;

        let { e, response } = await postForm('patch', 'procurements/supply/update-access', data);

        if (e) {
          displayError(e?.message);
        } else {
          dispatch(updateRecord(response.data.data[0]));
        }
      }
    };

  useEffect(() => {
    let removeOdds = async () => {
      const memberIDs = Members.filter((m) => m).map((m) => m?._id);
      let odds = selectedMembers.list.filter((m) => !memberIDs.includes(m));
      for (let i = 0; i < odds.length; i++) {
        await removeAccessWithUserId(odds[i]);
      }
    };
    removeOdds();
  }, [Members]);

  const onToggleGeneralAccess = async () => {
    if (selectedMembers.list.length > 1) {
      let unWantedMembers = Members.filter((m) => {
        return (
          m?._id &&
          m?._id !== selectedMembers.ownerId &&
          !selectedMembers.isCoOwner &&
          selectedMembers.list.includes(m?._id)
        );
      });

      for (let i = 0; i < unWantedMembers.length; i++) {
        await onRemoveAccess(unWantedMembers[i]);
      }
    } else {
      let newMembers = Members.filter((m) => m._id !== selectedMembers.ownerId);

      for (let i = 0; i < newMembers.length; i++) {
        await handleAccessGrant(newMembers[i]);
      }
    }
  };

  return (
    <AccessComponent
      onRemoveUserAccess={onRemoveAccess}
      onGrantUserAccess={handleAccessGrant}
      MemberAccessList={current?.access}
      title={`Supply Access`}
      isDisplay={selectedMembers.isDisplay}
      Members={Members}
      selectedUsers={selectedMembers.list}
      ownerId={selectedMembers.ownerId}
      closer={closer}
      onChangeAccessType={handleUpdateAccess('access')}
      accessTypeOptions={['viewer', 'editor', 'co-owner']}
      onToggleGeneralAccess={onToggleGeneralAccess}
    />
  );
};

export default SupplyAccessModal;
