import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Material } from './types';
import SuperModal from 'components/shared/SuperModal';
import InputField from 'components/shared/InputField';
import Button from 'components/shared/Button';
import SelectDate from 'components/shared/SelectDate';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addMaterialRecord, updateMaterialRecord, updateRecord } from 'store/slices/supplySlice';
import * as yup from 'yup';
import { StoreContext } from 'context';
import { postForm } from 'apis/postForm';
import { convertToNumber, displayError, displaySuccess, formatWithComma } from 'Utils';
import { TbMinus, TbPlus } from 'react-icons/tb';

import useSupply from 'Hooks/useSupply';
import { MaterialSelectFields } from 'components/shared';

interface Props {
  onAdd?: any;
  closer: () => void;
  isEditing?: boolean;
  value?: Material;
  activeTab?: string;
}

const initialValue = {
  supplyDate: new Date(),
  material: '',
  quantity: '0',
  unit: '',
  // rate: '0',
  // amount: '0',
  notes: '',
  category: '',
  vendor: '',
  orderNo: '',
  receivedBy: ''
};

const convertRecord = (val: Material) => {
  let newVal: { [key: string]: any } = { ...val };
  // newVal.amount = val.amount.toString();
  newVal.quantity = val.quantity.toString();
  // newVal.rate = val.rate.toString();

  return newVal as Material;
};

const AddSupplyModal = ({ onAdd, closer, isEditing, value, activeTab }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  // const { getRecords } = useSupply();
  const { activeSupply, data } = useAppSelector((m) => m.supply);

  let current = useMemo(() => {
    return data.find((m) => m._id === activeSupply);
  }, [data, activeSupply]);

  const { selectedData } = useContext(StoreContext);
  let { errors, values, setFieldValue, handleChange, handleSubmit, touched, resetForm } = useFormik(
    {
      initialValues: value ? convertRecord(value) : initialValue,
      onSubmit: (data) => {
        let _data: Material & { _id?: string } = {
          ...data
        } as Material;
        if (!_data?._id) {
          _data._id = Math.random().toString();
        }
        _data.quantity = convertToNumber(data.quantity as unknown as string);
        if (isEditing) {
          _edit(_data);
        } else _submit(_data);
      },
      validationSchema: yup.object({
        supplyDate: yup.string().required('Supply Date is required'),
        material: yup.string().required('Material is required'),
        quantity: yup.string().required().not(['0'], 'Field cannot be zero'),

        unit: yup.string().required('Unit is required'),
        vendor: yup.string().required('Vendor is required'),

        receivedBy: yup.string().required('Received By is required'),
        category: yup.string().required('Category is required'),
        orderNo: yup.string().required('Order Number is required'),
        notes: yup.string().required('notes is required')
      }),

      validateOnBlur: true
    }
  );

  // useEffect(() => {
  //   let quantity = convertToNumber(values.quantity as string);
  //   let rate = convertToNumber(values.rate as string);
  //   let finalAmount = quantity * rate;
  //   setFieldValue('amount', formatWithComma(finalAmount));
  // }, [values.quantity, values.rate]);

  const _submit = async (data: Omit<Material, '_id'> & { _id?: string }) => {
    setLoading(true);
    let _id = data._id;
    if (!isEditing) {
      delete data?._id;
    }

    const payload = {
      supplyId: current?._id,
      materials: [
        {
          supplyDate: data.supplyDate,
          material: data.material,
          quantity: convertToNumber(String(data.quantity)),
          vendor: data.vendor,
          receivedBy: data.receivedBy,
          unit: data.unit,
          category: data.category,
          notes: data.notes,
          orderNo: data.orderNo
        }
      ]
    };

    const { e, response } = await postForm('patch', `procurements/supply/add-material`, payload);

    if (response) {
      displaySuccess('Supply added successfully');
      const materialRecord = response.data.data[0];
      dispatch(updateRecord(materialRecord));
      resetForm();
      closer();
    } else {
      displayError(e?.message || '');
    }

    setLoading(false);
  };

  const _edit = async (data: Omit<Material, '_id'> & { _id?: string }) => {
    setLoading(true);
    const { _id, ...restData } = data;

    let _data = { ...restData } as any;

    for (let x of ['_id', 'project', '__v', 'createdBy', 'createdAt', 's_n']) {
      delete _data[x];
    }

    const payload = {
      supplyId: current?._id,
      materialId: _id,

      supplyDate: data.supplyDate,
      material: data.material,
      quantity: convertToNumber(String(data.quantity)),
      vendor: data.vendor,
      receivedBy: data.receivedBy,
      unit: data.unit,
      category: data.category,
      notes: data.notes,
      orderNo: data.orderNo
    };

    const { e, response } = await postForm('patch', `procurements/supply/update-material`, payload);

    if (response) {
      displaySuccess('Supply updated successfully');

      dispatch(
        updateMaterialRecord({
          supplyId: current?._id,
          materialId: _id,
          material: {
            supplyDate: data.supplyDate,
            material: data.material,
            quantity: convertToNumber(String(data.quantity)),
            unit: data.unit,
            vendor: data.vendor,
            category: data.category,
            notes: data.notes,
            receivedBy: data.receivedBy,

            orderNo: data.orderNo
          }
        })
      );

      resetForm();
      closer();
    } else displayError(e?.message || '');

    setLoading(false);
  };

  const _handleNumberChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val === '') {
      setFieldValue(field, '0');
      return;
    }
    let notString = /[^0-9.,]/i.test(val);
    if (notString) {
      return;
    }
    if (val[val.length - 1] === '.' && val[val.length - 2] !== '.') {
      setFieldValue(field, val);
      return;
    }
    if (val.endsWith('0') && /\.[\d]{1,}/i.test(val)) {
      setFieldValue(field, val);
      return;
    }

    let num = convertToNumber(val);
    if (!isNaN(num)) {
      let formatedValue = new Intl.NumberFormat('en-US').format(num);
      setFieldValue(field, formatedValue);
    }
  };

  const date = useMemo(() => {
    return new Date(values.supplyDate);
  }, [values.supplyDate]);

  return (
    <SuperModal
      classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={closer}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white rounded-md p-6 mt-20 mb-10 w-1/2 max-w-[500px] ">
        <div className="flex items-center justify-between mb-8">
          <p className=" text-xl font-medium">{isEditing ? 'Edit Supply' : 'Add Supply'}</p>

          <span className=" cursor-pointer text-sm text-bash" onClick={closer}>
            Close
          </span>
        </div>
        <InputField
          error={(touched?.orderNo && errors?.orderNo) || ''}
          name="orderNo"
          value={values.orderNo}
          onChange={handleChange}
          label="Order Number "
          placeholder="e.g RJI4345HJK"
          className=" !text-bblack-1"
        />
        <MaterialSelectFields
          categoryValue={values.category}
          materialValue={values.material}
          categoryError={(touched.category && errors.category) || ''}
          materialError={(touched.material && errors.material) || ''}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
        <InputField
          error={(touched?.receivedBy && errors?.receivedBy) || ''}
          name="receivedBy"
          value={values.receivedBy}
          onChange={handleChange}
          label="Recieved By "
          placeholder="Receiver’s name"
          className=" !text-bblack-1"
        />
        <div className="flex gap-x-4 items-center">
          <div className="w-[47%]">
            <p className=" text-bash">Quantity</p>
            <div className="flex rounded-md border px-2 py-1 items-center gap-x-2 border-ashShade-4 mt-1">
              <span
                onClick={(e) => {
                  let val = convertToNumber(values.quantity as string);
                  if (val > 0) {
                    setFieldValue('quantity', formatWithComma(val - 1));
                  }
                }}
                className="p-2 hover:bg-ashShade-0 rounded-full">
                <TbMinus />
              </span>

              <input
                className={`outline-none w-1/2 text-bblack-1 ${
                  touched?.quantity && errors?.quantity ? 'border-red-500' : ''
                }`}
                name="quantity"
                value={values.quantity}
                onChange={_handleNumberChange('quantity')}
                type="text"
                placeholder="Quantity"
              />

              <span
                onClick={(e) => {
                  let val = convertToNumber(values.quantity as string);
                  setFieldValue('quantity', formatWithComma(val + 1));
                }}
                className="p-2 hover:bg-ashShade-0 rounded-full">
                <TbPlus />
              </span>
            </div>
            {touched?.quantity && errors?.quantity && (
              <div className="text-red-500 text-sm">{errors.quantity}</div>
            )}
          </div>

          <InputField
            error={(touched.unit && errors.unit) || ''}
            name="unit"
            value={values.unit}
            onChange={handleChange}
            label="Unit"
            placeholder="e.g Sacks"
            className=" !flex-1 !text-bblack-1"
          />
        </div>
        <SelectDate
          className=""
          error={((touched.supplyDate && errors?.supplyDate) || '') as string}
          placeholder="Select date"
          wrapperClassName=" !border-ashShade-4 "
          minDate={new Date(0)}
          maxDate={new Date()}
          initialValue={value?.supplyDate ? new Date(value.supplyDate) : new Date()}
          value={date}
          label="Supplied Date"
          onChange={(e) => {
            if (e) {
              setFieldValue('supplyDate', e.toISOString());
            }
          }}
        />
        <InputField
          error={(touched.vendor && errors.vendor) || ''}
          name="vendor"
          value={values.vendor}
          onChange={handleChange}
          label="Vendor"
          placeholder="eg. Setraco"
          className=" !text-bblack-1"
        />

        <InputField
          isTextArea
          name="notes"
          value={values.notes}
          onChange={handleChange}
          label="Notes"
          placeholder="Enter a description..."
          error={(touched.notes && errors.notes) || ''}
          className=" !text-bblack-1 "
        />
        <div className=" flex justify-end gap-x-4">
          <Button onClick={closer} type="secondary" text="Cancel" />

          <Button
            isLoading={loading}
            onClick={() => {
              handleSubmit();
            }}
            text={isEditing ? 'Save Changes' : 'Record Supply'}
          />
        </div>
      </div>
    </SuperModal>
  );
};

export default AddSupplyModal;
