import { useContext } from 'react';
import SuperModal from 'components/shared/SuperModal';
import PowFromProjectCard from './PowFromProjectCard';
import { MdOutlineClose } from 'react-icons/md';
import type { POW } from '../../types';
import { ProfessionalBrief } from 'types';
import useFetch from 'Hooks/useFetch';
import Loader from 'components/shared/Loader';
import type { ProjectTask } from '../../types';
import { PMStoreContext } from '../../Context';

interface Props {
  closer: () => void;
  pows: POW[];
  name: string;
  project: ProfessionalBrief;
}

const SelectPowModal = ({ closer, pows, name, project }: Props) => {
  const { isLoading: isTaskLoading } = useFetch<ProjectTask[]>({ showDisplayError: false });
  const { handleSelectSubmit, selectedProgram } = useContext(PMStoreContext);
  return (
    <>
      {isTaskLoading ? (
        <Loader />
      ) : (
        <SuperModal
          classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
          closer={closer}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="flex flex-col gap-4 bg-white rounded-md p-6 mt-20 w-1/2 max-w-[700px]">
            <div className="flex justify-between items-center w-full">
              <p className="text-2xl font-semibold">{name}</p>
              <span onClick={closer} className="cursor-pointer">
                <MdOutlineClose size={24} color="#9099A8" />
              </span>
            </div>
            <p className="font-medium">Select program of works</p>
            <div className="grid md:grid-cols-2 gap-6">
              {project &&
                pows.map((pow) => {
                  return (
                    <PowFromProjectCard
                      key={pow._id}
                      template={pow}
                      project={project}
                      // selectedProgram={selectedProgram}
                      // handleSelect={handleSelect}
                    />
                  );
                })}
            </div>
            <div className=" flex justify-end gap-x-4">
              <button onClick={closer} className="py-2 px-8 border border-ashShade-1 rounded-md">
                Cancel
              </button>
              <button
                className={`py-2 px-8 border  rounded-md ${
                  selectedProgram
                    ? 'bg-bblue text-white border-bblue'
                    : 'bg-ashShade-3 border-ashShade-4 text-bash'
                }`}
                onClick={handleSelectSubmit}>
                Select Program
              </button>
            </div>
          </div>
        </SuperModal>
      )}
    </>
  );
};
export default SelectPowModal;
