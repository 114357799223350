import { FC, useContext, useRef, useState } from 'react';
import { Material } from '../types';
import { FaEllipsisVertical } from 'react-icons/fa6';
import { TheOption } from 'pages/projects/Home/Components/ProjectCard';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { useClickOutSideComponent } from 'components/projects/Team/Views/Components/OnScreen';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { removeMaterialRecord } from 'store/slices/supplySlice';
import { postForm } from 'apis/postForm';
import { displayError, displaySuccess, formatWithComma } from 'Utils';
import { StoreContext } from 'context';

import DeleteModal from './DeleteModal';
import SupplyItemCard from './SupplyItemCard';

export interface Props extends Material {
  s_n: number;
  setEditing?: (m: Material) => void;
  onEdit: (material: Material) => void;
}

const toggler = (val: any, setter: any) => () => {
  setter(!val);
};
const SupplyTableItem = (m: Props) => {
  let { data, activeProject } = useContext(StoreContext);
  const [options, setOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [showDetails, setShowDetails] = useState(false);
  const [viewDetails, setviewDetails] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editing, setEditing] = useState<Material | undefined>(undefined);
  const optionsRef = useRef<any>();
  const { activeSupply } = useAppSelector((m) => m.supply);
  useClickOutSideComponent(optionsRef, () => {
    if (!loading) {
      setOptions(false);
    }
  });

  const descriptionRef = useRef<any>();
  useClickOutSideComponent(descriptionRef, () => {
    if (!loading) {
      setOptions(false);
    }
  });

  let dispatch = useAppDispatch();

  const handleDelete = async () => {
    setLoading(true);
    const payLoad = {
      supplyId: activeSupply,
      materialId: m._id
    };
    let { e, response } = await postForm('patch', `procurements/supply/remove-material`, payLoad);

    if (response) {
      displaySuccess('Supply removed Successfully');
      dispatch(removeMaterialRecord(payLoad));
    } else {
      displayError(e?.message || 'Could not remove supply');
    }
    setLoading(false);
  };

  const handleDeleteSubmit = () => {
    setLoading(true);
    handleDelete();

    setShowDeleteModal(false);
    setLoading(false);
  };
  const capitalizeFirstLetter = (str: string) => {
    if (!str) return 'N/A'; 
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <>
      {showDeleteModal && (
        <DeleteModal
          closer={() => {
            setShowDeleteModal(false);
          }}
          handleDeleteSubmit={handleDeleteSubmit}
          title="Delete Supply"
          deleteWhat="supply"
          buttonText="Delete supply"
        />
      )}

      <tr className=" py-4 text-bash text-sm w-full h-full ">
        <td className="pl-4">{m.s_n ?? 'N/A'}</td>
        <td className="py-2 uppercase">{m.orderNo ?? 'N/A'}</td> 
        <td className="py-2 capitalize">{capitalizeFirstLetter(m.material)}</td>
        <td className="py-2 capitalize">
          {formatWithComma(m.quantity)} {capitalizeFirstLetter(m.unit)}
        </td>
        <td className="py-2 capitalize">{capitalizeFirstLetter(m.vendor)}</td>
        <td className="py-2 capitalize">{capitalizeFirstLetter(m.receivedBy)}</td>
        <td className="py-2">{new Date(m.supplyDate).toDateString().slice(4)}</td>
        <td
          onMouseEnter={() => setviewDetails(true)}
          onMouseLeave={() => setviewDetails(false)}
          className="py-2 relative hover:cursor-pointer">
          <span className="text-bash underline font-medium">
            {viewDetails ? 'Hide details' : 'More details'}
          </span>
          {viewDetails && (
            <span className="absolute z-50 text-sm m min-w-[30vw] max-w-[460px] right-0 rounded-md top-[-150px] text-white">
              <SupplyItemCard item={m} />
            </span>
          )}
        </td>
        <td className="py-2 relative ">
          <div>
            <span
              onClick={() => {
                setOptions(true);
              }}
              className=" p-2 w-8 h-8 flex rounded-full  hover:bg-ashShade-0 ">
              <FaEllipsisVertical />
            </span>

            {options && (
              <div
                ref={optionsRef}
                className=" top-6 z-10 right-2 absolute bg-white   rounded-md shadow-bnkle lg:w-[136px] ">
                <TheOption
                  icon={TbEdit}
                  text="Edit"
                  onClick={() => m.onEdit(m)}
                  className=" px-4"
                />
                <TheOption
                  loading={loading}
                  className=" hover:text-redShades-2 text-redShades-2 hover:bg-redShades-1 px-4"
                  iconColor="#B63434"
                  iconHoveredColor="#B63434"
                  icon={TbTrash}
                  text="Delete"
                  onClick={() => setShowDeleteModal(true)}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default SupplyTableItem;
