export const categories = [
  {
    category: { label: 'Miscellaneous', value: 'Miscellaneous' },
    materials: [
      { label: 'Pegs', value: 'Pegs' },
      { label: '1 x 2 -profile', value: '1 x 2 -profile' },
      { label: 'Cement, 50kg bag', value: 'Cement, 50kg bag' },
      { label: 'Sharp sand (30 tonnes)', value: 'Sharp sand (30 tonnes)' },
      { label: 'Granite (30 tonnes)', value: 'Granite (30 tonnes)' },
      { label: 'Plaster sand (30 tonnes)', value: 'Plaster sand (30 tonnes)' },
      { label: 'Hardcore (30 tonnes)', value: 'Hardcore (30 tonnes)' },
      { label: 'Laterite (30 tonnes)', value: 'Laterite (30 tonnes)' }
    ]
  },
  {
    category: { label: 'Dpm', value: 'Dpm' },
    materials: [
      { label: 'Dpm', value: 'Dpm' },
      { label: 'Anti termite solution; 4 litre', value: 'Anti termite solution; 4 litre' }
    ]
  },
  {
    category: { label: 'Formwork', value: 'Formwork' },
    materials: [
      { label: 'Marine plywood', value: 'Marine plywood' },
      { label: '2" x 3" x 12" (planks)', value: '2" x 3" x 12" (planks)' },
      { label: '2" x 4" x 12" (planks)', value: '2" x 4" x 12" (planks)' },
      { label: '1" x 12" x 12" (planks)', value: '1" x 12" x 12" (planks)' },
      { label: '1" nail; 25kg', value: '1" nail; 25kg' },
      { label: '1 1/2" 25kg', value: '1 1/2" 25kg' },
      { label: '1 1/2" 25kg', value: '1 1/2" 25kg' },
      { label: '3" 25kg', value: '3" 25kg' },
      { label: '4" 25kg', value: '4" 25kg' },
      { label: '5" 25kg', value: '5" 25kg' },
      { label: 'Concrete nails', value: 'Concrete nails' }
    ]
  },
  {
    category: { label: 'Reinforcements', value: 'Reinforcements' },
    materials: [
      { label: '20mm Diameter bar', value: '20mm Diameter bar' },
      { label: '16mm Diameter bar', value: '16mm Diameter bar' },
      { label: '12mm Diameter bar', value: '12mm Diameter bar' },
      { label: '10mm Diameter bar', value: '10mm Diameter bar' },
      { label: 'Binding wires (25 kg)', value: 'Binding wires (25 kg)' },
      { label: 'BRC mesh (3.5mm thick)', value: 'BRC mesh (3.5mm thick)' }
    ]
  },
  {
    category: { label: 'Blocks', value: 'Blocks' },
    materials: [
      { label: '9" Blocks', value: '9" Blocks' },
      { label: '6" Blocks', value: '6" Blocks' },
      { label: '4" Blocks', value: '4" Blocks' }
    ]
  },
  {
    category: { label: 'Roof Trusses', value: 'Roof Trusses' },
    materials: [
      { label: '50 X 150 X 12"', value: '50 X 150 X 12"' },
      { label: '50 X 100 X 12"', value: '50 X 100 X 12"' },
      { label: '50 X 75 X 12"', value: '50 X 75 X 12"' },
      { label: '50 X 50 X 12"', value: '50 X 50 X 12"' },
      { label: 'Nails', value: 'Nails' },
      { label: 'Solignum treatment', value: 'Solignum Treatment' }
    ]
  },
  {
    category: { label: 'Roof Covering', value: 'Roof Covering' },
    materials: [
      {
        label: 'Roof covering-0.55mm longspan Aluminium roofing sheet',
        value: 'Roof covering-0.55mm longspan Aluminium roofing sheet'
      },
      { label: 'Ridge Capping', value: 'Ridge Capping' },
      { label: 'Valley Capping', value: 'Valley Capping' },
      { label: 'Roofing Nails', value: 'Roofing Nails' }
    ]
  },
  {
    category: { label: 'Aluminium Casement Windows', value: 'Aluminium Casement Windows' },
    materials: [
      { label: 'Overall size; 750 x 1500mm high', value: 'Overall size; 750 x 1500mm high' },
      { label: 'Overall size; 2000 x 1250mm high', value: 'Overall size; 2000 x 1250mm high' },
      { label: 'Overall size; 1000 x 1250mm high', value: 'Overall size; 1000 x 1250mm high' },
      { label: 'Overall size; 450 x 2200mm high', value: 'Overall size; 450 x 2200mm high' },
      { label: 'Overall size; 600 x 2200mm high', value: 'wOverall size; 600 x 2200mm high' },
      { label: 'Overall size; 750 x 2100mm high', value: 'Overall size; 750 x 2100mm high' },
      { label: 'Overall size; 900 x 2200mm high', value: 'Overall size; 900 x 2200mm high' },
      { label: 'Overall size; 600 x 3735mm high', value: 'Overall size; 600 x 3735mm high' },
      { label: 'Overall size; 1000 x 2100mm high', value: 'Overall size; 1000 x 2100mm high' },
      { label: 'Overall size; 600 x 5500mm high', value: 'Overall size; 600 x 5500mm high' }
    ]
  },
  {
    category: { label: 'Burglary Bars', value: 'Burglary Bars' },
    materials: [
      { label: 'Overall size; 750 x 1500mm high', value: 'Overall size; 750 x 1500mm high' },
      { label: 'Overall size; 2000 x 1250mm high', value: 'Overall size; 2000 x 1250mm high' },
      { label: 'Overall size; 1000 x 1250mm high', value: 'Overall size; 1000 x 1250mm high' },
      { label: 'Overall size; 450 x 2200mm high', value: 'Overall size; 450 x 2200mm high' },
      { label: 'Overall size; 600 x 2200mm high', value: 'Overall size; 600 x 2200mm high' },
      { label: 'Overall size; 750 x 2100mm high', value: 'Overall size; 750 x 2100mm high' },
      { label: 'Overall size; 900 x 2200mm high', value: 'Overall size; 900 x 2200mm high' },
      { label: 'Overall size; 600 x 3735mm high', value: 'Overall size; 600 x 3735mm high' },
      { label: 'Overall size; 1000 x 2100mm high', value: 'Overall size; 1000 x 2100mm high' },
      { label: 'Overall size; 600 x 5500mm high', value: 'Overall size; 600 x 5500mm high' }
    ]
  },
  {
    category: { label: 'Timber Flush Doors', value: 'Timber Flush Doors' },
    materials: [
      { label: 'Overall size; 750 x 2100mm high', value: 'Overall size; 750 x 2100mm high' },
      { label: 'Overall size; 900 x 2100mm high', value: 'Overall size; 900 x 2100mm high' }
    ]
  },
  {
    category: { label: 'Steel Security Doors', value: 'Steel Security Doors' },
    materials: [
      { label: 'Overall size; 900 x 2100mm high', value: 'Overall size; 900 x 2100mm high' },
      { label: 'Overall size; 1200 x 2100mm high', value: 'Overall size; 1200 x 2100mm high' },
      { label: 'Overall size; 1500 x 2100mm high', value: 'Overall size; 1500 x 2100mm high' }
    ]
  },
  {
    category: { label: 'Ceiling', value: 'Ceiling' },
    materials: [
      { label: 'POP Cement', value: 'POP Cement' },
      { label: 'Filler', value: 'Filler' },
      { label: 'Binding wire', value: 'Binding wire' },
      { label: 'Concrete Nails', value: 'Concrete Nails' },
      { label: 'Nails', value: 'Nails' },
      { label: 'Oil', value: 'Oil' },
      { label: 'Soap', value: 'Soap' },
      {
        label: 'Celotex ceiling board; size 600x600x12mm thk.',
        value: 'Celotex ceiling board; size 600x600x12mm thk.'
      }
    ]
  },
  {
    category: { label: 'POP Screeding', value: 'pop-screeding' },
    materials: [
      { label: 'POP Cement', value: 'POP Screeding' },
      { label: 'Acurate POP paint', value: 'Acurate POP paint' },
      { label: 'Water seal', value: 'Water seal' },
      { label: 'Top Bond (10kg)', value: 'Top Bond (10kg)' }
    ]
  },
  {
    category: { label: 'Tiling', value: 'Tiling' },
    materials: [
      { label: 'Granite Slabs', value: 'Granite Slabs' },
      { label: 'Granite Floor Tiles', value: 'Granite Floor Tiles' },
      { label: 'Glazed Floor Tiles', value: 'Glazed Floor Tiles' },
      { label: 'Unglazed Floor Tiles', value: 'Unglazed Floor Tiles' },
      { label: 'Tiling Gum', value: 'Tiling Gum' },
      { label: 'White Cement', value: 'White Cement' },
      { label: 'Wall Tiles', value: 'Wall Tiles' },
      { label: 'Stainless Steel Railings', value: 'Stainless Steel Railings' },
      {
        label: 'Kitchen/Store Cabinets Complete with All Accessories',
        value: 'Kitchen/Store Cabinets Complete with All Accessories'
      },
      {
        label: 'Wardrobes/Shelves Complete with Accessories',
        value: 'Wardrobes/Shelves Complete with Accessories'
      }
    ]
  },
  {
    category: { label: 'Mechanical Installation', value: 'Mechanical Installation' },
    materials: [
      { label: 'Water Closet', value: 'Water Closet' },
      { label: 'Wash Hand Basin', value: 'Wash Hand Basin' },
      { label: 'Shower Cubicle', value: 'Shower Cubicle' },
      { label: 'Bathtub', value: 'Bathtub' },
      { label: 'Toilet Paper Holder', value: 'Toilet Paper Holder' },
      { label: 'Towel Rail', value: 'Towel Rail' },
      { label: 'Soap Dish', value: 'Soap Dish' },
      { label: 'Toilet Brush', value: 'Toilet Brush' },
      { label: 'Floor Drain', value: 'Floor Drain' },
      { label: 'Mirror', value: 'Mirror' },
      { label: 'Water Heater', value: 'Water Heater' },
      { label: 'Kitchen Sink', value: 'Kitchen Sink' },
      { label: 'Mixer Tap', value: 'Mixer Tap' },
      { label: '6" Pressure Pipe (4 Bar)', value: '6" Pressure Pipe (4 Bar)' },
      { label: '4" Pressure Pipe (4 Bar)', value: '4" Pressure Pipe (4 Bar)' },
      { label: '3" Pressure Pipe (4 Bar)', value: '3" Pressure Pipe (4 Bar)' },
      { label: '2" Pressure Pipe', value: '2" Pressure Pipe' },
      { label: '4" x 90 Elbow (Tigre)', value: '4" x 90 Elbow (Tigre)' },
      { label: '2" Tee (Tigre)', value: '2" Tee (Tigre)' },
      { label: '2" x 90 Elbow (Tigre)', value: '2" x 90 Elbow (Tigre)' },
      { label: '3" x 90 Elbow (Tigre)', value: '3" x 90 Elbow (Tigre)' },
      { label: '2" x 45 Elbow (Tigre)', value: '2" x 45 Elbow (Tigre)' },
      { label: '4" x 45 Elbow (Tigre)', value: '4" x 45 Elbow (Tigre)' },
      { label: '4" Y Joint', value: '4" Y Joint' },
      { label: '2" Y Joint', value: '2" Y Joint' },
      { label: '4 x 2 Tee (Tigre)', value: '4 x 2 Tee (Tigre)' },
      { label: 'PVC Gum (Abro)', value: 'PVC Gum (Abro)' },
      { label: '100mm Pan Connector', value: '100mm Pan Connector' },
      { label: 'Vent Cowl', value: 'Vent Cowl' },
      { label: '32mm Pipes', value: '332mm Pipes' },
      { label: '32mm Elbow', value: '32mm Elbow' },
      { label: '32mm Tee', value: '32mm Tee' },
      { label: '32mm Socket', value: '32mm Socket' },
      { label: '32mm Gate Valves', value: '32mm Gate Valves' },
      { label: '25mm Pipes', value: '25mm Pipes' },
      { label: '25 x 32mm Elbow', value: '25 x 32mm Elbow' },
      { label: '25mm Socket', value: '25mm Socket' },
      { label: '20 x 25mm Elbow', value: '20 x 25mm Elbow' },
      { label: '25mm Elbow', value: '25mm Elbow' },
      { label: '25mm Tee', value: '25mm Tee' },
      { label: '25mm Gate Valves', value: '25mm Gate Valves' },
      { label: '20mm Pipes', value: '20mm Pipes' },
      { label: '20mm Finished Elbow', value: '20mm Finished Elbow' },
      { label: '20mm Elbow', value: '20mm Elbow' },
      { label: '20mm Tee', value: '20mm Tee' },
      { label: '20mm Finished Tee', value: '20mm Finished Tee' },
      { label: '20mm Socket', value: '20mm Socket' },
      { label: '20mm Gate Valves', value: '20mm Gate Valves' },
      { label: 'PVC Gum (Abro)', value: 'PVC Gum (Abro)' },
      { label: 'Angle Valve', value: 'Angle Valve' },
      { label: 'Flexible Connector', value: 'Flexible Connector' }
    ]
  },
  {
    category: { label: 'Electrical Materials', value: 'Electrical Materials' },
    materials: [
      {
        label: '12W LED Ceiling Mounted Lighting Fitting',
        value: '12W LED Ceiling Mounted Lighting Fitting'
      },
      {
        label: '300x300 12W LED Lighting Fitting',
        value: '300x300 12W LED Lighting Fitting'
      },
      {
        label: '1x40W Bulkhead Fitting with Transparent Diffuser',
        value: '1x40W Bulkhead Fitting with Transparent Diffuser'
      },
      {
        label: '12W LED Recessed/Surface Mounted Lighting Fitting',
        value: '12W LED Recessed/Surface Mounted Lighting Fitting'
      },
      { label: '6x20W Chandelier Fitting Outdoor', value: '6x20W Chandelier Fitting Outdoor' },
      {
        label: '6x20W Chandelier Fitting Suspended with Chain',
        value: '6x20W Chandelier Fitting Suspended with Chain'
      },
      { label: '6x20W Chandelier Fitting', value: '6x20W Chandelier Fitting' },
      { label: 'Mirror Light', value: 'Mirror Light' },
      { label: '3x20W Dining Light Circular', value: '3x20W Dining Light Circular' },
      { label: '3x20W Pendant for Kitchen Island', value: '3x20W Pendant for Kitchen Island' },
      { label: 'Photocell Switch', value: 'Photocell Switch' },
      { label: 'Bedside Lamp', value: 'Bedside Lamp' },
      { label: 'Extractor Fan', value: 'Extractor Fan' },
      { label: '6kg Dry Powder Fire Extinguisher', value: '6kg Dry Powder Fire Extinguisher' },
      { label: '9kg Fire Extinguisher', value: '9kg Fire Extinguisher' },
      { label: '15A 1 Gang 1 Way Switch', value: '15A 1 Gang 1 Way Switch' },
      { label: '10A 1 Gang 2 Way Switch', value: '10A 1 Gang 2 Way Switch' },
      { label: '1x13A Switch Socket Outlet', value: '11x13A Switch Socket Outlet' },
      { label: '2x13A Switch Socket Outlet', value: '2x13A Switch Socket Outlet' },
      { label: '15A Switch Socket Outlet-AC', value: '15A Switch Socket Outlet-AC' },
      { label: '20A Switch Socket Outlet-WH', value: '20A Switch Socket Outlet-WH' },
      { label: '45A Switch Socket Outlet-CCU', value: '45A Switch Socket Outlet-CCU' },
      { label: 'Telephone Outlet', value: 'Telephone Outlet' },
      { label: 'TV Outlet', value: 'TV Outlett' },
      { label: '100A Subpanel', value: '100A Subpanel' },
      { label: '100A TPN Service Cut Out', value: '100A TPN Service Cut Out' },
      {
        label: '60A; 4-Way TPN MCB Incomer Distribution Board Complete',
        value: '60A; 4-Way TPN MCB Incomer Distribution Board Complete'
      },
      { label: '60A TP&N Circuit Breaker', value: '60A TP&N Circuit Breaker' },
      { label: '4 x 16mm2 Armoured Cable', value: '4 x 16mm2 Armoured Cable' },
      { label: '1.5mm2 Cables', value: '1.5mm2 Cables' },
      { label: '2.5mm2 Cables', value: '2.5mm2 Cables' },
      { label: '4.0mm2 Cables', value: '4.0mm2 Cables' },
      { label: '6.0mm2 Cables', value: '6.0mm2 Cables' },
      { label: '1.5mm2 Green Earthing Cables', value: '1.5mm2 Green Earthing Cables' },
      { label: '20mm Conduit Pipe', value: '20mm Conduit Pipe' },
      { label: '25mm Conduit Pipe', value: '25mm Conduit Pipe' },
      { label: '20mm Coupler', value: '20mm Coupler' },
      { label: '25mm Coupler', value: '25mm Coupler' },
      { label: '20mm 4-Way Box', value: '220mm 4-Way Box' },
      { label: '25mm 4-Way Box', value: '25mm 4-Way Box' },
      { label: '2-Way Box', value: '2-Way Box' },
      { label: 'Knockout Boxes 3 x 3', value: 'Knockout Boxes 3 x 3' },
      { label: '3Knockout Boxes 3 x 6', value: 'Knockout Boxes 3 x 6' },
      { label: 'Cooker Box (6x6)', value: 'Cooker Box (6x6)' },
      { label: 'Male Bush', value: 'Male Bush' },
      { label: 'Abro Gum', value: 'Abro Gum' }
    ]
  },
  {
    category: { label: 'Painting Materials', value: 'Painting Materials' },
    materials: [
      { label: 'Emulsion Paint', value: 'Emulsion Paint' },
      { label: 'Textured Paint', value: 'Textured Paint' }
    ]
  }
];
