import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { SupplyMaterialRecord } from 'components/projects/procurement/supply/types';

export interface Contractor {}

type InitType = {
  loading?: boolean;
  data: SupplyMaterialRecord[];
  activeSupply?: string;
};

let initialState: InitType = {
  loading: false,
  data: []
};

export const supplySlice = createSlice({
  name: 'supply',
  initialState,
  reducers: {
    loadRecords: (state, action) => {
      let newState = state as any;
      for (let x in action.payload) {
        newState[x] = action.payload[x];
      }
      return newState;
    },

    addRecord: (state, action) => {
      let newState = state;
      newState.data.push(action.payload);
      return newState;
    },

    addMaterialRecord: (state, action) => {
      return {
        ...state,
        data: state.data.map((supply) => {
          if (supply._id === action.payload.materialId) {
            return {
              ...supply,
              supplys: [...(supply.materials || []), action.payload.supply]
            };
          }
          return supply;
        })
      };
    },

    updateRecord: (state, action) => {
      let newState = state;
      newState.data = newState.data.map((m) => {
        if (m?._id === action.payload?._id) {
          return action.payload;
        }
        return m;
      });

      return newState;
    },

    updateMaterialRecord: (state, action) => {
      return {
        ...state,
        data: state.data.map((supply) => {
          if (supply._id === action.payload.supplyId) {
            return {
              ...supply,
              materials: supply.materials.map((material: any) => {
                if (material._id === action.payload.materialId) {
                  return {
                    ...material,
                    ...action.payload.material
                  };
                }
                return material;
              })
            };
          }
          return supply;
        })
      };
    },

    removeRecord: (state, action) => {
      let newState = state;
      newState.data = newState.data.filter((m) => m._id !== action.payload);
      return newState;
    },

    removeMaterialRecord: (state, action) => {
      return {
        ...state,
        data: state.data.map((supply) => {
          if (supply._id === action.payload.supplyId) {
            return {
              ...supply,
              materials: supply.materials.filter(
                (material) => material._id !== action.payload.materialId
              )
            };
          }
          return supply;
        })
      };
    }
  }
});

export const {
  loadRecords,
  updateRecord,
  updateMaterialRecord,
  removeRecord,
  removeMaterialRecord,
  addRecord,
  addMaterialRecord
} = supplySlice.actions;

export const record = (state: RootState) => state.supply;
export default supplySlice.reducer;
