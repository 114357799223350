import { useState, useEffect, useContext } from 'react';
import { TbBed, TbClock } from 'react-icons/tb';
import buildingIcon from 'assets/buildingType.svg';
import type { POW } from '../../types';
import { ProfessionalBrief } from 'types';
import useFetch from '../../../../../Hooks/useFetch';
import type { ProjectTask } from '../../types';
import { getTasksByPOW } from 'apis/tasks';
import { PMStoreContext } from '../../Context';

interface Props {
  template: POW;
  project: ProfessionalBrief;
}

const PowTemplateCard = ({ template, project }: Props) => {
  const [tasks, setTasks] = useState<ProjectTask[]>([]);
  const { name, _id } = template;
  const { selectedProgram, handleSelect } = useContext(PMStoreContext);
  const { load: TaskLoad } = useFetch<ProjectTask[]>({ showDisplayError: false });
  const subTasks = tasks.map((task) => task.subTasks);
  const totalDuration = tasks.reduce((acc, task) => {
    return acc + task.subTasks.reduce((acc, subTask) => acc + subTask.duration.value, 0);
  }, 0);

  const fetchTasks = async () => {
    try {
      const tasks = await TaskLoad(getTasksByPOW(_id)).then((res) => res.data);
      setTasks(tasks);
    } catch (er) {
      setTasks([]);
      console.log(er);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [tasks]);

  return (
    <>
      <div
        onClick={() => handleSelect(template, tasks)}
        className={`flex flex-col gap-4 px-4 py-6  rounded-md border  cursor-pointer hover:bg-bbg ${
          selectedProgram === template ? 'border-bblue bg-bbg' : 'border-ashShade-3 bg-white'
        } `}>
        <p className="font-semibold capitalize">{name}</p>
        <div className="flex gap-1 items-center text-bash">
          <span className="flex items-center gap-1 text-bash">
            <TbBed color="#77828D" size={16} />
            <p className="text-sm">{project.numberOfBedrooms} Bedroom</p>
          </span>
          &bull;
          <span className="flex items-center gap-1 text-bash">
            <img src={buildingIcon} alt="duplex" className="w-4 h-4 text-bash" />
            <p className="text-bash text-sm capitalize">{project.projectType}</p>
          </span>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="flex gap-2 items-center text-xs">
            <span className="bg-bbg text-bblue py-1 px-2 rounded-3xl">
              {tasks.length ?? 0} Task{tasks.length > 1 && 's'}
            </span>
            <span className="bg-[#E9F6F2] text-[#26A87D] py-1 px-2 rounded-3xl">
              {subTasks.length ?? 0} Subtask{subTasks.length > 1 && 's'}
            </span>
          </div>
          <span className="flex items-center gap-1 text-bash text-xs">
            <TbClock color="#77828D" size={16} />
            {totalDuration} day{totalDuration > 1 && 's'}
          </span>
        </div>
      </div>
    </>
  );
};
export default PowTemplateCard;
