import React from 'react'

const CategoryGallery = () => {
  return (
    <div>CategoryGallery</div>
  )
}

export default CategoryGallery
// import React, { useEffect, useMemo, useState } from 'react';
// import Layout from '../Home/Layout';
// import { Outlet, useNavigate, useParams } from 'react-router-dom';
// import { FaArrowLeftLong, FaPlus } from 'react-icons/fa6';
// import { TbSearch } from 'react-icons/tb';
// import Button from 'components/shared/Button';
// import { list } from './constants';
// import GroupCard from './components/GroupCard';
// import BackButton from './components/BackButton';
// import { postForm } from 'apis/postForm';
// import { useAppDispatch, useAppSelector } from 'store/hooks';
// import { loadCategories } from 'store/slices/tutorials';
// import GhostBids, { GhostCard } from 'components/projects/bids/projectowner/bid/GhostBids';
// import NoContent from 'components/projects/photos/NoContent';
// import VideoCard from './components/VideoCard';
// import { convertToProper } from 'components/shared/utils';
// import UploadVideoModal, { TutorialModalWrapper } from './components/UploadVideoModal';
// import { CategoryGallery as CategoryGalleryType } from './types';
// import useRole from 'Hooks/useRole';

// const CategoryGallery = () => {
//   const { data, loading } = useAppSelector((m) => m.tutorials);
//   const [modal, setModal] = useState(false);
//   const [editing, setEditing] = useState<CategoryGalleryType>();
//   const [search, setSearch] = useState('');
//   const { isPortfolioManager } = useRole();
//   let params = useParams();
//   let navigate = useNavigate();

//   let current = useMemo(() => {
//     let _current = data.find((m) => m._id === params.id);
//     if (!loading) {
//       if (!_current) {
//         navigate('');
//       } else {
//         return _current;
//       }
//     }
//   }, [params, loading, data]);

//   const filtered = useMemo(() => {
//     return (
//       current?.gallery.filter((m) => m.title.toLowerCase().includes(search.toLowerCase())) || []
//     );
//   }, [current?.gallery, search]);

//   const handleEdit = (vid: CategoryGalleryType) => {
//     return () => {
//       setEditing(vid);
//       setModal(true);
//     };
//   };

//   return (
//     <div className="w-full ">
//       {modal && (
//         <UploadVideoModal
//           closer={() => {
//             setModal(false);
//           }}
//           editing={editing}
//         />
//       )}
//       <BackButton />
//       <p className=" text-3xl font-bold my-6 ">Tutorials</p>
//       <div className=" mt-6 flex flex-col md:flex-row  md:items-center justify-between  ">
//         <p className=" text-2xl font-bold ">{convertToProper(current?.name)}</p>

//         <div className=" flex items-center gap-x-4  ">
//           <div className=" bg-white rounded-md flex items-center p-2 border border-ashShade-2  ">
//             <TbSearch className=" mr-2" color="#9099A8" />
//             <input
//               value={search}
//               onChange={(e) => {
//                 setSearch(e.target.value);
//               }}
//               placeholder="Search"
//               className=" outline-none "
//             />
//           </div>
//           {isPortfolioManager && (
//             <Button
//               onClick={() => {
//                 setModal(!modal);
//               }}
//               type="primary"
//               className="  "
//               text="Upload Video"
//               LeftIcon={<FaPlus className="mr-2" />}
//             />
//           )}
//         </div>
//       </div>

//       {loading || (current && current?.gallery?.length > 0) ? (
//         <div
//           className={`  w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6 gap-x-5 gap-y-6 `}>
//           {loading ? (
//             Array(4)
//               .fill('ff')
//               .map((m) => <GhostCard />)
//           ) : current && current?.gallery?.length > 0 ? (
//             filtered.length > 0 ? (
//               filtered.map((m) => <VideoCard handleEdit={handleEdit(m)} {...m} key={m._id} />)
//             ) : (
//               <div>No Search results for &quot;{search}&quot;</div>
//             )
//           ) : (
//             <NoContent title="No Videos uploaded yet" subtitle="  " />
//           )}
//         </div>
//       ) : (
//         <NoContent title="No Videos uploaded yet" subtitle="  " />
//       )}
//     </div>
//   );
// };

// export default CategoryGallery;
