import { postForm } from 'apis/postForm';
import AccessComponent from 'components/projects/documents/document-repo/AccessComponent';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { displayError } from 'Utils';
import { Access } from '../../layout/types';
import { useParams } from 'react-router-dom';
import { updateProcurement } from 'store/slices/procurementSlice';

interface DashboardAccessModalProps {
  closer: () => void;
}

const DashboardAccessModal = ({ closer }: DashboardAccessModalProps) => {
  let { data } = useAppSelector((m) => m.procurement);
  let { data: _members, loading } = useAppSelector((m) => m.team);
  let user = useAppSelector((m) => m.user);
  let dispatch = useDispatch();
  let { procurementId } = useParams();

  const current = useMemo(() => {
    return data.find((m) => m._id === procurementId)?.dashboard;
  }, [data, procurementId]);

  let selectedMembers = useMemo(() => {
    let ownerId = '';
    let isCoOwner = false;

    let list =
      current?.access.map((m) => {
        if (m.roleType === 'owner') ownerId = m.userId;
        if (m.roleType === 'co-owner' && m.userId === user._id) {
          isCoOwner = true;
        }
        return m.userId;
      }) || [];
    let isDisplay = ownerId !== user._id && !isCoOwner;
    return { list, ownerId, isDisplay, isCoOwner };
  }, [current]);

  let Members = useMemo(() => {
    if (!loading) {
      let all = Object.values(_members) || [];
      return all.filter((m) => m?._id && m?.name?.toLowerCase() !== 'general');
    }
    return [];
  }, [_members, loading, current]);

  const handleAccessGrant = async (user: Record<string, any>) => {
    let { e, response } = await postForm('patch', 'procurements/dashboard/add-access', {
      dashboardId: procurementId,
      access: [
        {
          userId: user._id,
          role: user.role,
          roleType: 'member',
          accessType: 'viewer'
        }
      ]
    });

    if (e) {
      displayError(e?.message);
    } else {
      dispatch(updateProcurement(response.data.data));
    }
  };

  const onRemoveAccess = async (user: Record<string, any>) => {
    let { e, response } = await postForm('patch', 'procurements/dashboard/remove-access', {
      dashboardId: procurementId,
      userId: user._id
    });

    if (e) {
      displayError(e?.message);
    } else {
      dispatch(updateProcurement(response.data.data));
    }
  };

  const RemoveAccessWithUserId = async (id: string) => {
    let { e, response } = await postForm('patch', 'procurements/dashboard/remove-access', {
      dashboardId: procurementId,
      userId: id
    });

    if (e) {
      displayError(e?.message);
    } else {
      dispatch(updateProcurement(response.data.data));
    }
  };

  const onChange = (type: 'role' | 'access') => async (user: Record<string, any>, val: string) => {
    let userAccess = current?.access?.find((m) => m.userId === user._id);
    if (userAccess) {
      let data = {
        dashboardId: procurementId,
        ...(userAccess as Partial<Access>)
      };
      const isCoOwner = val === 'co-owner';

      if (isCoOwner) {
        data.accessType = 'editor';
        data.roleType = 'co-owner';
      } else {
        data.accessType = val;
        data.roleType = 'member';
      }

      delete data?._id;
      delete data?.role;
      let { e, response } = await postForm('patch', 'procurements/dashboard/update-access', data);

      if (e) {
        displayError(e?.message);
      } else {
        dispatch(updateProcurement(response.data.data));
      }
    }
  };

  useEffect(() => {
    let removeOdds = async () => {
      const memberIDs = Members.filter((m) => m).map((m) => m?._id);
      let odds = selectedMembers.list.filter((m) => !memberIDs.includes(m));
      for (let i = 0; i < odds.length; i++) {
        await RemoveAccessWithUserId(odds[i]);
      }
    };

    removeOdds();
  }, [Members]);

  const onToggleGeneralAccess = async () => {
    if (selectedMembers.list.length > 1) {
      let unwantedMembers = Members.filter((m) => {
        return (
          m?._id &&
          m?._id !== selectedMembers.ownerId &&
          !selectedMembers.isCoOwner &&
          selectedMembers.list.includes(m?._id)
        );
      });

      for (let i = 0; i < unwantedMembers.length; i++) {
        await onRemoveAccess(unwantedMembers[i]);
      }
    } else {
      let newMembers = Members.filter((m) => m._id !== selectedMembers.ownerId);

      for (let i = 0; i < newMembers.length; i++) {
        await handleAccessGrant(newMembers[i]);
      }
    }
  };

  return (
    <AccessComponent
      onRemoveUserAccess={onRemoveAccess}
      onGrantUserAccess={handleAccessGrant}
      MemberAccessList={current?.access}
      title={'Dashboard' + ' Access'}
      isDisplay={selectedMembers.isDisplay}
      Members={Members}
      selectedUsers={selectedMembers.list}
      ownerId={selectedMembers.ownerId}
      closer={closer}
      onChangeAccessType={onChange('access')}
      accessTypeOptions={['viewer', 'editor', 'co-owner']}
      onToggleGeneralAccess={onToggleGeneralAccess}
    />
  );
};
export default DashboardAccessModal;
