import { displayError } from 'Utils';
import { postForm } from 'apis/postForm';
import { useProcurementDetails } from 'components/projects/procurement/layout/useProcurement';
import { StoreContext } from 'context';
import React, { useContext, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { loadInventory } from 'store/slices/inventorySlice';

const useInventory = (wait: boolean = false) => {
  const inventoryId = '66d1d39be8158bb66844a8db';
  let { selectedProject } = useContext(StoreContext);
  let dispatch = useAppDispatch();
  let { procurementItems } = useProcurementDetails();

  // useEffect(() => {
  //   dispatch(loadInventory({ activeSupply: procurementItems?.supplyId }));
  // }, [procurementItems]);


  const getRecords = async () => {
    dispatch(loadInventory({ loading: true }));
    let { e, response } = await postForm(
      'get',
      `procurements/inventory/list?projectId=${selectedProject?._id}`
    );

    if (response) {
      dispatch(loadInventory({ data: response.data.data, loading: false }));
    } else {
      dispatch(loadInventory({ loading: false }));
      displayError(e?.message || 'Could not fetch records');
    }
  };

  useEffect(() => {
    if (!wait) {
      getRecords();
    }
  }, []);

  useEffect(() => {
    getRecords();
  }, [selectedProject?._id]);

  return { getRecords };
};

export default useInventory;
