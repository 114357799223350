import { FC, useContext, useRef, useState } from 'react';
import {  MaterialRecord, MaterialScheduleRecord, ScheduleAccess } from '../types';
import { FaEllipsisVertical } from 'react-icons/fa6';
import { TheOption } from 'pages/projects/Home/Components/ProjectCard';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { useClickOutSideComponent } from 'components/projects/Team/Views/Components/OnScreen';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { removeMaterialRecord } from 'store/slices/materialScheduleSlice';
import { postForm } from 'apis/postForm';
import { displayError, displaySuccess, formatWithComma } from 'Utils';
import { StoreContext } from 'context';
import MaterialItemCard from './MaterialItemCard';
import DeleteModal from './DeleteModal';

export interface Props extends MaterialRecord {
  s_n: number;
  activeTab: string;
  setEditing?: (m: MaterialRecord) => void;
  onEdit: (material: MaterialRecord) => void; 
  access?: ScheduleAccess[];
}


const RecordTableItem: React.FC<Props> = (m) => {
  let { data, activeProject } = useContext(StoreContext);
  const [options, setOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editing, setEditing] = useState<MaterialRecord | undefined>(undefined);
  const optionsRef = useRef<any>();
  useClickOutSideComponent(optionsRef, () => {
    if (!loading) {
      setOptions(false);
    }
  });

  const descriptionRef = useRef<any>();
  useClickOutSideComponent(descriptionRef, () => {
    if (!loading) {
      setOptions(false);
    }
  });

  let dispatch = useAppDispatch();
  let user = useAppSelector((m) => m.user);

  const optionsAccess = m?.access?.some(
    (a) =>
      a.userId === user._id &&
      (a.roleType === 'owner' || a.roleType === 'co-owner' || a.accessType === 'editor')
  );

 

  const handleDelete = async () => {
    setLoading(true);
    const payLoad = {
      scheduleId: m.activeTab,
      materialId: m._id
    };
    let { e, response } = await postForm(
      'patch',
      `procurements/material-schedule/delete-material`,
      payLoad
    );
    if (response) {
      displaySuccess('Material removed Successfully');
      dispatch(removeMaterialRecord(payLoad));
    } else {
      displayError(e?.message || 'Could not remove material');
    }
    setLoading(false);
  };

  const handleDeleteSubmit = () => {
    setLoading(true);
    handleDelete();

    setShowDeleteModal(false);
    setLoading(false);
  };

  return (
    <>
      {showDeleteModal && (
        <DeleteModal
          closer={() => {
            setShowDeleteModal(false);
          }}
          handleDeleteSubmit={handleDeleteSubmit}
          title="Delete Schedule Material"
          deleteWhat="material"
          buttonText="Delete Schedule Material"
        />
      )}

      <tr className=" py-4 text-bash text-sm w-full ">
        <td className=" pl-4">{m.s_n}</td>
        <td className="py-2">{m.material}</td>
        <td className="py-2">
          {formatWithComma(m.quantity)} {m.unit}
        </td>
        <td className="py-2">
          {activeProject?.currency?.code} {formatWithComma(m.rate)}
        </td>
        <td className="py-2  ">
          {activeProject?.currency?.code} {formatWithComma(m.amount)}
        </td>
        <td
        onMouseEnter={() => setShowDetails(true)}
        onMouseLeave={() => setShowDetails(false)}
          className="py-2 relative hover:cursor-pointer">
          <span className=" text-bash underline font-medium">
            {`${showDetails ? 'Hide' : 'View'} note`}
          </span>

          {showDetails && <MaterialItemCard item={m} setShowDetails={setShowDetails} />}
        </td>
        <td className="py-2">
          {m.category}
        </td>

       
        {optionsAccess && (
 <td className="py-2 relative ">
          <div>
            <span
              onClick={() => {
                setOptions(true);
              }}
              className=" p-2 w-8 h-8 flex rounded-full  hover:bg-ashShade-0 ">
              <FaEllipsisVertical />
            </span>

            {options && (
              <div
                ref={optionsRef}
                className=" top-6 z-10 right-2 absolute bg-white   rounded-md shadow-bnkle lg:w-[136px] ">
                <TheOption
                  icon={TbEdit}
                  text="Edit"
                  onClick={() => m.onEdit(m)}
                  className=" px-4"
                />
                <TheOption
                  loading={loading}
                  className=" hover:text-redShades-2 text-redShades-2 hover:bg-redShades-1 px-4"
                  iconColor="#B63434"
                  iconHoveredColor="#B63434"
                  icon={TbTrash}
                  text="Delete"
                  onClick={() => setShowDeleteModal(true)}
                />
              </div>
            )}
          </div>
        </td>
        )}
       
      </tr>
    </>
  );
};

export default RecordTableItem;
