import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { Inventory } from 'components/projects/procurement/inventory/types';
// import { inventoryData } from 'components/projects/procurement/inventory/constants';

export interface Contractor {}

let initialState: {
  loading?: boolean;
  data: Inventory[];
} = {
  loading: false,
  data: []
};

export const inventorySlice = createSlice({
  name: 'inventoryRegister',
  initialState,
  reducers: {
    loadInventory: (state, action) => {
      let newState = state;
      for (let x in action.payload) {
        newState[x as keyof typeof initialState] = action.payload[x];
      }
      return newState;
    },
    addInventory: (state, action) => {
      let newState = state;
      newState.data.push(action.payload);
      return newState;
    },
    updateInventory: (state, action) => {
      let newState = state;
      newState.data = newState.data.map((m) => {
        if (m._id === action.payload._id) {
          return action.payload;
        }
        return m;
      });
      return newState;
    },
    deleteInventory: (state, action) => {
      let newState = state;
      newState.data = newState.data.filter((m) => m._id !== action.payload);
      return newState;
    },
    addInventoryMaterial: (state, action) => {
      return {
        ...state,
        data: state.data.map((inventory) => {
          if (inventory._id === action.payload.inventoryId) {
            return {
              ...inventory,
              materials: [...inventory.materials, action.payload.material]
            };
          }
          return inventory;
        })
      };
    },
    updateInventoryMaterial: (state, action) => {      
      return {
        ...state,
        data: state.data.map((inventory) => {
          if (inventory._id === action.payload.inventoryId) {
            return {
              ...inventory,
              materials: inventory.materials.map((material) => {
                if (material._id === action.payload.materialId) {
                  return action.payload.material;
                }
                return material;
              })
            };
          }
          return inventory;
        })
      };
    },
    removeInventoryMaterial: (state, action) => {
      return {
        ...state,
        data: state.data.map((inventory) => {
          if (inventory._id === action.payload.inventoryId) {
            return {
              ...inventory,
              materials: inventory.materials.filter((m) => m._id !== action.payload.materialId)
            };
          }
          return inventory;
        })
      };
    }
  }
});

export const {
  loadInventory,
  addInventory,
  updateInventory,
  deleteInventory,
  updateInventoryMaterial,
  removeInventoryMaterial,
  addInventoryMaterial
} = inventorySlice.actions;

export const record = (state: RootState) => state.inventoryRegister;
export default inventorySlice.reducer;
