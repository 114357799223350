import { FC, useContext } from 'react';
import { SupplyMaterialRecord, Material } from '../types';
import { formatWithComma } from 'Utils';
import { StoreContext } from 'context';

const Details: FC<{ label: string; value: string }> = ({ label, value }) => (
  <div className="flex justify-between border-b py-4">
    <p className="text-sm font-satoshi font-medium text-bblack-1">{label}</p>
    <p className="text-sm font-satoshi font-medium text-bash">{value}</p>
  </div>
);

interface SupplyItemCardProps {
  item: Material;
}

const SupplyItemCard: FC<SupplyItemCardProps> = ({ item }) => {
  let { data, activeProject } = useContext(StoreContext);

  const capitalizeFirstLetter = (str: string) => {
    if (!str) return 'N/A';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <div className="flex flex-col gap-4 p-6 rounded-md absolute z-10 bg-white shadow-bnkle top-10 w-[28rem] right-16 mb-20 ">
      <p className="text-2xl font-satoshi font-medium text-black capitalize">supply details</p>

      <div className="flex flex-col px-4 bg-ashShade-0 rounded-md capitalize ">
        <Details label="Order Number" value={item.orderNo.toUpperCase()} />
        <Details label="Material" value={capitalizeFirstLetter(item.material)} />
        <Details
          label="Quantity"
          value={`${formatWithComma(item.quantity)} ${capitalizeFirstLetter(item.unit)}`}
        />
        <Details label="Vendor" value={capitalizeFirstLetter(item.vendor)} />
        <Details label="Received By" value={capitalizeFirstLetter(item.receivedBy)} />
        <Details label="Category" value={capitalizeFirstLetter(item.category)} />
        <Details label="Date" value={new Date(item.supplyDate).toDateString().slice(4)} />
        {/* <Details
          label="Rate"
          value={`${activeProject?.currency?.code} ${formatWithComma(item.rate)}`}
        />
        <Details
          label="Amount"
          value={`${activeProject?.currency?.code} ${formatWithComma(item.amount)}`}
        /> */}
      </div>
      <div className="flex flex-col gap-2 ">
        <p className="text-sm font-satoshi font-medium text-bblack-1">Notes</p>
        <p className="text-sm font-satoshi font-medium text-bash capitalize">{item.notes}</p>
      </div>
    </div>
  );
};

export default SupplyItemCard;
