import React, { useMemo, useState } from 'react';
import SelectField from 'components/shared/SelectField';
import { categories } from './constant';
import InputField from '../InputField';

interface Props {
  categoryError?: string;
  materialError?: string;
  categoryValue: string;
  materialValue: string;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MaterialSelectFields: React.FC<Props> = ({
  categoryError,
  materialError,
  categoryValue,
  materialValue,
  handleChange,
  setFieldValue
}) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [isManualInputMode, setIsManualInputMode] = useState<boolean>(false);

  const toggleInputMode = () => {
    setIsManualInputMode((prev) => !prev);
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
    setFieldValue('category', value);
    setFieldValue('material', '');
    setSelectedMaterial('');
  };

  const handleMaterialChange = (value: string) => {
    setSelectedMaterial(value);
    setFieldValue('material', value);
  };

  const sortedCategories = useMemo(
    () => categories.map((c) => c.category).sort((a, b) => a.label.localeCompare(b.label)),
    []
  );

  const selectedCategoryMaterials = useMemo(
    () => categories.find((cat) => cat.category.value === selectedCategory)?.materials || [],
    [selectedCategory]
  );

  const sortedMaterials = useMemo(
    () => selectedCategoryMaterials.sort((a, b) => a.label.localeCompare(b.label)),
    [selectedCategoryMaterials]
  );

  return (
    <>
      {!isManualInputMode ? (
        <>
          <SelectField
            error={categoryError}
            placeholder="Select a category"
            value={categoryValue}
            data={sortedCategories}
            onChange={handleCategoryChange}
            showSearch
            label="Category"
            className="!flex-1 !text-bblack-1"
            linkLabel="Not listed?"
            toggleInputMode={toggleInputMode}
          />
          <SelectField
            error={materialError}
            placeholder="Select a material"
            value={materialValue}
            onChange={handleMaterialChange}
            data={sortedMaterials}
            disabled={!selectedCategory}
            showSearch
            label="Material"
            className="!flex-1 !text-bblack-1"
          />
        </>
      ) : (
        <>
          <InputField
            error={categoryError}
            placeholder="Select a category"
            name="category"
            value={categoryValue}
            onChange={handleChange}
            label="Category"
            className=" !flex-1 !text-bblack-1"
            linkLabel="Select from list"
            toggleInputMode={toggleInputMode}
          />
          <InputField
            error={materialError}
            placeholder="Select a material"
            name="material"
            value={materialValue}
            onChange={handleChange}
            label="Material"
            className=" !flex-1 !text-bblack-1"
          />
        </>
      )}
    </>
  );
};
