import { FC, useCallback, useState } from 'react';
import Button from 'components/shared/Button';
import SuperModal from 'components/shared/SuperModal';
import { CgClose } from 'react-icons/cg';
import { displayError, displaySuccess } from 'Utils';
import { TbMinus, TbPlus, TbRefresh } from 'react-icons/tb';
import InputField from 'components/shared/InputField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DashBoardItem } from '../../layout/types';
import { postForm } from 'apis/postForm';
import { useParams } from 'react-router-dom';
import { hoverFade } from 'constants/globalStyles';
import { updateProcurement } from 'store/slices/procurementSlice';
import { useAppDispatch } from 'store/hooks';
import { Tooltip } from 'react-tooltip';

interface Props {
  closer: () => void;
  item: DashBoardItem;
}

export const ThresholdModal: FC<Props> = ({ closer, item }) => {
  const [loading, setLoading] = useState(false);

  let { procurementId } = useParams();
  const dispatch = useAppDispatch();

  let { values, setFieldValue, handleChange, handleSubmit, resetForm, isValid, dirty } = useFormik({
    initialValues: { threshold: item.threshold },
    onSubmit: () => {
      handleUpdateThreshold();
    },
    validationSchema: yup.object({
      threshold: yup
        .number()
        .required('Threshold is required')
        .min(1, 'Threshold must be greater than zero')
    }),
    validateOnBlur: true
  });

  const submitThresholdUpdate = useCallback(
    async (newThreshold: number, successMessage?: string) => {
      setLoading(true);

      const payload = {
        dashboardId: procurementId,
        materialId: item._id,
        threshold: newThreshold
      };

      const { e, response } = await postForm('patch', `procurements/update-threshold`, payload);
      if (response) {
        displaySuccess(successMessage ?? 'Threshold updated successfully');
        dispatch(updateProcurement(response.data?.data));
        resetForm();
        closer();
      } else {
        displayError(e?.message || '');
      }

      setLoading(false);
    },
    [procurementId, item, values, closer, resetForm]
  );

  const handleUpdateThreshold = () => submitThresholdUpdate(values.threshold);

  const handleResetThreshold = () => submitThresholdUpdate(0, 'Procurement restored successfully');

  const handleQuantityChange = (delta: number) => {
    const newThreshold = Math.max(0, values.threshold + delta);
    setFieldValue('threshold', newThreshold);
  };

  return (
    <SuperModal
      classes="bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={closer}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white rounded-md p-6 mt-20 mb-10 w-1/2 max-w-[520px]">
        <div>
          <div className="flex items-center justify-between mb-8">
            <p className="text-xl font-medium font-satoshi text-bblack-1">
              Dangote cement threshold
            </p>
            <span className={hoverFade + 'text-sm text-bash'} onClick={closer}>
              <CgClose fontSize={24} color="#9099A8" />
            </span>
          </div>
          <p className="text-sm font-medium font-satoshi text-bash pb-2">
            Set limit below which you will get notified that you are low in this material
          </p>
        </div>
        <div className="lg:flex gap-x-4 items-center">
          <div className="lg:w-[50%]">
            <div className="flex items-center gap-2">
              <p className="text-bash">Threshold quantity</p>
              <div className={hoverFade + 'refresh'} onClick={handleResetThreshold}>
                <TbRefresh color="#437ADB" size={16} />
                <Tooltip
                  place="top"
                  anchorSelect=".refresh"
                  style={{
                    backgroundColor: '#24282E',
                    color: '#fff',
                    borderRadius: '8px',
                    fontSize: '14px'
                  }}>
                  Reset Threshold
                </Tooltip>
              </div>
            </div>
            <div className="flex rounded-md border px-2 py-1 items-center justify-between gap-x-2 border-ashShade-4 mt-1">
              <span
                onClick={() => handleQuantityChange(-1)}
                className="p-2 hover:bg-ashShade-0 rounded-full">
                <TbMinus />
              </span>
              <input
                className="outline-none w-1/2 text-bblack-1"
                name="threshold"
                value={values.threshold.toString()}
                onChange={handleChange}
                type="text"
                placeholder="quantity"
              />
              <span
                onClick={() => handleQuantityChange(1)}
                className="p-2 hover:bg-ashShade-0 rounded-full">
                <TbPlus />
              </span>
            </div>
          </div>
          <div className="lg:w-[50%]">
            <InputField
              name="unit"
              value={item.unit}
              onChange={handleChange}
              label="Unit"
              placeholder="e.g Bags"
              className="!flex-1 !text-bblack-1"
              disabled
            />
          </div>
        </div>
        <div className=" flex justify-end gap-x-4">
          <Button onClick={closer} type="secondary" text="Cancel" />
          <Button
            isLoading={loading}
            onClick={() => {
              handleSubmit();
            }}
            text="Set threshold"
            type={isValid && dirty ? 'primary' : 'muted'}
          />
        </div>
      </div>
    </SuperModal>
  );
};
