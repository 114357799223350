import { flexer, hoverFade } from 'constants/globalStyles';
import { StoreContext } from 'context';
import React, { useContext, useEffect } from 'react';
import { NavLink, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { tabs } from './constants';
import MaterialHeader from '../material-schedule/components/MaterialHeader';
import useProcurement from './useProcurement';
import { useAppSelector } from 'store/hooks';
import { LoaderX } from 'components/shared/Loader';
import TopProcurementTabs from '../components/TopProcurementTabs';

const Index = () => {
  const { selectedProjectIndex, activeProject } = useContext(StoreContext);

  let { createProcurement, getProcurements } = useProcurement();
  const { data, loading } = useAppSelector((m) => m.procurement);
  useEffect(() => {
    getProcurements();
  }, [activeProject]);
  let navigate = useNavigate();
  let { procurementId } = useParams();

  useEffect(() => {
    if (!loading && data.length > 0 && !procurementId) navigate(data[0]._id);
  }, [data, loading, procurementId]);

  return (
    <div className=" flex-1 overflow-y-auto min-h-screen overflow-x-auto">
      <div className="flex-1 overflow-y-auto  min-h-screen">
        <div className={flexer + ' mb-2'}>
          <h1 className="text-2xl font-Medium">Procurement</h1>
        </div>
        <TopProcurementTabs />
        <Outlet />
      </div>
    </div>
  );
};

export default Index;
