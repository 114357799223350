import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { ProjectTask, TaskStatus, POW, SubTask } from '../types';
import { StoreContext } from 'context';
import { ProfessionalBrief } from 'types';

export type PMContextState = {
  isLoading: boolean;
  tasks: { [key: string]: ProjectTask[] }; // { powId: tasks[] }
  activePow?: POW;
  draftsToSubmit: ProjectTask[];
  activeSubTask?: SubTask;
  activeTask?: ProjectTask;
  taskStatusFilter?: TaskStatus;
  hasFetchedAllPows: boolean;
  hasFetchedAllTasks: boolean;
  pows: POW[];
  powImporting: POW | null;
  selectedProgram: POW | null;
  selectedTasks: ProjectTask[];
  isPowImport: boolean;
  isTaskImport: boolean;
  targetProject: ProfessionalBrief | null;
  showDetailsModal: boolean;
  showSelectModal: boolean;
  detailsModalType: null | 'Pow' | 'Task';
};

interface Props {
  children?: ReactNode;
}

type handleContext = <T extends keyof PMContextState, Y extends PMContextState[T]>(
  key: T,
  value: Y
) => void;

interface ContextProps extends PMContextState {
  handleContext: handleContext;
  setContext: React.Dispatch<React.SetStateAction<PMContextState>>;
  handlePowImporting: (program: POW) => void;
  handleTaskImporting: (program: POW) => void;
  handleProjectClick: (project: ProfessionalBrief) => void;
  handleSelect: (program: POW, tasks: ProjectTask[]) => void;
  handleSelectSubmit: () => void;
  handleSelectClose: () => void;
  handleDetailsClose: () => void;
}

const initialState: ContextProps = {
  pows: [],
  tasks: {},
  isLoading: false,
  draftsToSubmit: [],
  setContext: () => {},
  handleContext: () => {},
  hasFetchedAllPows: false,
  hasFetchedAllTasks: false,
  powImporting: null,
  selectedProgram: null,
  isPowImport: false,
  isTaskImport: false,
  targetProject: null,
  showDetailsModal: false,
  showSelectModal: false,
  detailsModalType: null,
  selectedTasks: [],
  handlePowImporting: () => {},
  handleTaskImporting: () => {},
  handleProjectClick: () => {},
  handleSelect: () => {},
  handleSelectSubmit: () => {},
  handleSelectClose: () => {},
  handleDetailsClose: () => {}
};

const PMStoreContext = createContext<ContextProps>(initialState);

const PMStoreProvider: FC<Props> = ({ children }) => {
  const { selectedProject } = useContext(StoreContext);
  const [state, setState] = useState<PMContextState>(initialState);

  useEffect(() => {
    /** check to see if the project has changed, and clear */
    const exists = state.pows.find((one) => one.project === selectedProject._id);
    if (!exists) {
      // clear all POWs
      setState((prev) => ({ ...prev, pows: [], hasFetchedAllPows: false }));
    }
  }, [selectedProject]);

  const handleContext: handleContext = (key, value) => {
    if (key === 'activeTask' && value === undefined) {
      setState((prev) => {
        if (!prev.activeTask) return prev;
        /**
         *  when aborting
         *  active task (viewing one task)
         *  save the current instance
         */
        const newTasks = prev.tasks[prev.activeTask.powId].map((one) =>
          one._id === prev.activeTask?._id ? prev.activeTask : one
        );
        return {
          ...prev,
          tasks: { ...prev.tasks, [prev.activeTask.powId]: newTasks },
          activeTask: undefined
        };
      });
    } else {
      setState((prev: PMContextState) => ({ ...prev, [key]: value }));
    }
  };

  const handlePowImporting = (program: POW) => {
    setState((prev) => ({
      ...prev,
      isPowImport: true,
      powImporting: program,
      detailsModalType: 'Pow'
    }));
  };

  const handleTaskImporting = (program: POW) => {
    setState((prev) => ({
      ...prev,
      powImporting: program,
      isTaskImport: true,
      detailsModalType: 'Task'
    }));
  };

  const handleProjectClick = (project: ProfessionalBrief) => {
    setState((prev) => ({
      ...prev,
      targetProject: project,
      selectedProgram: null,
      showSelectModal: true
    }));
  };

  const handleSelect = (program: POW, tasks: ProjectTask[]) => {
    setState((prev) => ({
      ...prev,
      selectedProgram: program,
      selectedTasks: tasks
    }));
  };

  const handleSelectSubmit = () => {
    setState((prev) => ({
      ...prev,
      isPowImport: false,
      isTaskImport: false,
      showSelectModal: false,
      showDetailsModal: true
    }));
  };

  const handleSelectClose = () => {
    setState((prev) => ({
      ...prev,
      selectedProgram: null,
      showSelectModal: false
    }));
  };

  const handleDetailsClose = () => {
    setState((prev) => ({
      ...prev,
      selectedProgram: null,
      showDetailsModal: false
    }));
  };

  return (
    <PMStoreContext.Provider
      value={{
        ...state,
        handleContext,
        setContext: setState,
        handlePowImporting,
        handleTaskImporting,
        handleProjectClick,
        handleSelect,
        handleSelectSubmit,
        handleSelectClose,
        handleDetailsClose
      }}>
      {children}
    </PMStoreContext.Provider>
  );
};

export { PMStoreContext, PMStoreProvider };
