import Button from 'components/shared/Button';
import { useEffect, useState, useMemo, useContext } from 'react';
import { TbPlus } from 'react-icons/tb';
import { useAppSelector } from 'store/hooks';
import MaterialScheduleTable from './MaterialScheduleTable';
import { MaterialRecord } from './types';
import ProcurementTabs from '../layout/ProcurementTabs';
import { BsFunnel } from 'react-icons/bs';
import { TbAccessible } from 'react-icons/tb';
import AddMaterialRecord from './components/MaterialHeader';
import Filter from './Filter';
import { MaterialTableCard } from './components/MaterialTableCard';
import { HiOutlineCash } from 'react-icons/hi';
import { TbListNumbers } from 'react-icons/tb';
import { formatWithComma } from 'Utils';
import { StoreContext } from 'context';
import useMaterialSchedule from 'Hooks/useMaterialSchedule';
import TabsMaterialSchedule from './components/TabsMaterialSchedule';
import NoContent from 'components/projects/photos/NoContent';
import { TOption } from 'components/shared/SelectField/SelectField';
import AddMaterialModal from './AddMaterialModal';
import ScheduleAccessModal from './components/ScheduleAccessModal';
import { useParams } from 'react-router-dom';
import useProcurement, { useProcurementDetails } from '../layout/useProcurement';
import { LoaderX } from 'components/shared/Loader';

const MaterialSchedule = () => {
  const [activeTab, setActiveTab] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showAddMaterial, setShowAddMaterial] = useState(false);

  const [editing, setEditing] = useState<MaterialRecord | undefined>(undefined);

  const [filters, setFilters] = useState<{ [key: string]: (Date | string)[] }>({
    material: [],
    category: [],
    date: []
  });
  const { getRecords } = useMaterialSchedule();
  let { selectedProject } = useContext(StoreContext);
  let { data, loading } = useAppSelector((m) => m.materialSchedule);
  let { data: procurementData, loading: _loading } = useAppSelector((m) => m.procurement);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const { procurementId } = useParams();
  const { procurementItems } = useProcurementDetails();
  let user = useAppSelector((m) => m.user);

  const answer = data?.find((item, index) => item?._id === activeTab);

  const hasAccess = useMemo(() => {
    let access = answer?.access?.find((m) => m.userId === user._id);
    return access;
  }, [answer]);

  useEffect(() => {
    if (data.length < 1) {
      getRecords();
    }
  }, []);

  useEffect(() => {
    setActiveTab(procurementItems?.scheduleId || '');
  }, [procurementItems]);

  const onMaterialClick = (material: MaterialRecord) => {
    setActiveTab(material?._id);
  };

  const handleEditClick = (material: MaterialRecord) => {
    setEditing(material);
    setShowModal(true);
  };

  const toggleAddMaterial = () => {
    setShowAddMaterial((prev) => !prev);
  };

  let { materialData, categoryData } = useMemo(() => {
    const materialMap = new Map(
      answer?.materials.map((item) => [item.material.toLowerCase(), item.material])
    );

    const tempMaterial = Array.from(materialMap.values()).map((material) => ({
      value: material,
      label: material,
      icon: ''
    }));

    const categoryMap = new Map(
      answer?.materials.map((item) => [item.category.toLowerCase(), item.category])
    );

    const categoryTemp = Array.from(categoryMap.values()).map((category) => ({
      value: category,
      label: category,
      icon: ''
    }));

    return {
      materialData: tempMaterial,
      categoryData: categoryTemp
    };
  }, [answer?.materials]);

  const handleFilter = () => {
    let _data = answer?.materials;

    if (filters?.material && filters.material.length > 0) {
      let materials = filters?.material as string[];
      let _materials = materials?.map((m) => m.toLowerCase());
      _data = answer?.materials?.filter((m) => _materials.includes(m?.material?.toLowerCase()));
    }

    if (filters?.category && filters?.category.length > 0) {
      let categories = filters?.category as string[];
      let _categories = categories?.map((m) => m.toLowerCase());
      _data = answer?.materials?.filter((m) => _categories.includes(m?.category.toLowerCase()));
    }

    if (filters?.date && filters?.date.length > 0) {
      let dates = filters?.date as Date[];
      let _dates = dates.map((m) => m.getTime());
      if (_dates[0] !== _dates[1]) {
        _data = answer?.materials?.filter((m) => {
          let date = new Date(m.timestamp).getTime();
          return _dates[0] <= date && date <= _dates[1];
        });
      }
    }

    return _data;
  };

  const filtered = useMemo(handleFilter, [answer, filters]);

  const { totalAmount, items } = useMemo(() => {
    let totalAmount = 0;
    let items = filtered?.length || 0;

    filtered?.forEach((m) => {
      if (m) {
        totalAmount += Number(m.amount);
      }
    });

    return {
      totalAmount,
      items
    };
  }, [filtered]);

  return loading || _loading ? (
    <div className="flex bg-white mt-2 items-center p-10 rounded-2xl justify-center">
      <LoaderX color="blue" />
    </div>
  ) : procurementId ? (
    <>
      {showAccessModal && (
        <ScheduleAccessModal
          _id={activeTab}
          closer={() => {
            setShowAccessModal(false);
          }}
        />
      )}
      <ProcurementTabs
        buttons={
          <>
            {hasAccess && (
              <div className="relative">
                <div className="flex gap-x-2 ">
                  <Button
                    className="border-ashShade-2 border px-0 mx-0"
                    text=""
                    type="plain"
                    style={{
                      padding: '9px 18px'
                    }}
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                    LeftIcon={<BsFunnel color="#9099A8" />}
                  />
                  <Button
                    onClick={() => {
                      setShowAccessModal(true);
                    }}
                    className="border-ashShade-2 border px-0 mx-0"
                    text=""
                    type="plain"
                    style={{
                      padding: '9px 18px'
                    }}
                    LeftIcon={<TbAccessible color="#9099A8" />}
                  />
                  <Button
                    onClick={() => toggleAddMaterial()}
                    text=""
                    style={{
                      padding: '9px 18px'
                    }}
                    LeftIcon={<TbPlus color="white" />}
                  />
                </div>
                {showAddMaterial && (
                  <AddMaterialRecord
                    onClose={() => setShowAddMaterial(false)}
                    activeTab={activeTab}
                  />
                )}
              </div>
            )}
          </>
        }
      />

      {showModal && (
        <AddMaterialModal
          isEditing={Boolean(editing)}
          value={editing}
          closer={() => {
            setShowModal(false);
            setEditing(undefined);
          }}
          activeTab={activeTab}
        />
      )}

      {!hasAccess ? (
        <NoContent title="You do not have access" subtitle="  " />
      ) : (
        <div className="bg-white px-6 ">
          <div className=" ">
            <div className="flex items-center justify-between">
              <h2 className="font-Medium text-2xl">Material Schedule</h2>
            </div>
          </div>

          {showFilter && (
            <div className="w-full my-4">
              <Filter
                onChange={(val: { [key: string]: (string | Date)[] }) => {
                  setFilters(val);
                }}
                materialData={materialData as TOption[]}
                categoryData={categoryData as TOption[]}
              />
            </div>
          )}

          {data.length > 0 && (
            <div className="my-[1.2em] flex items-center  w-full justify-between gap-5">
              <MaterialTableCard
                topClassName="!bg-ashShade-16"
                className=" bg-bgreen-4"
                icon={<HiOutlineCash fontSize={24} color="#4F7411" />}
                label="Total Amount"
                value={`${
                  selectedProject?.currency?.code ? selectedProject?.currency?.code : ''
                } ${formatWithComma(totalAmount)}`}
              />

              <MaterialTableCard
                topClassName="!bg-ashShade-16"
                className=" bg-lightblue "
                icon={<TbListNumbers fontSize={24} color="#437ADB" />}
                label="Number of Items"
                value={`${items} ${items > 0 ? 'materials' : 'material'}`}
              />
            </div>
          )}

          {/* <TabsMaterialSchedule data={data} activeTab={activeTab} onMaterialClick={onMaterialClick} /> */}

          {procurementData.length > 0 && data && data.length > 0 ? (
            <MaterialScheduleTable
              data={filtered || []}
              loading={loading}
              onEdit={handleEditClick}
              activeTab={activeTab}
              access={answer?.access || []}
            />
          ) : (
            <NoContent
              subtitle="Keep a record of materials needed to complete the project"
              title="No Material recorded Yet"
            />
          )}
        </div>
      )}
    </>
  ) : (
    <NoContent title="No procurement selected" subtitle=" " />
  );
};

export default MaterialSchedule;
