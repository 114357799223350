import React, { useContext, useEffect, useMemo, useState } from 'react';
import ProcurementTab from './ProcurementTab';
import useProcurement from '../layout/useProcurement';
import { useAppSelector } from 'store/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StoreContext } from 'context';
import Button from 'components/shared/Button';
import { TbPlus } from 'react-icons/tb';
import { LoaderX } from 'components/shared/Loader';

const TopProcurementTabs = () => {
  let { getProcurements, createProcurement } = useProcurement(true);
  const { loading, data } = useAppSelector((m) => m.procurement);
  const [creating, setCreating] = useState(false);
  let navigate = useNavigate();
  let { projectId, procurementId } = useParams();

  const handleCreate = async () => {
    setCreating(true);
    await createProcurement();
    setCreating(false);
  };
  return (
    <div className=" w-full ">
      <div className="flex w-full items-center ">
        <div className=" flex-1  flex overflow-x-auto">
          {loading ? (
            <div className="flex bg-white mt-2 items-center p-10 rounded-2xl justify-center">
              <LoaderX color="blue" />
            </div>
          ) : (
            data.map((m) => (
              <ProcurementTab
                key={m._id}
                name={m.name}
                onClick={() => navigate(`/projects/${projectId}/procurement/${m._id}`)}
                isActive={m._id === procurementId}
              />
            ))
          )}

          <span
            className=" flex items-center justify-center rounded-t-md bg-lightblue px-5 py-2 "
            onClick={handleCreate}>
            {creating ? (
              <LoaderX subClass=" !w-3 !h-3" color="blue" />
            ) : (
              <TbPlus className=" text-bblue" />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopProcurementTabs;
