import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashBoardItem } from 'components/projects/procurement/layout/types';

interface ProcurementFavoriteState {
  data: DashBoardItem[];
}

const initialState: ProcurementFavoriteState = {
  data: []
};

export const procurementFavoriteSlice = createSlice({
  name: 'procurementFavorite',
  initialState,
  reducers: {
    loadFavorite: (state, action) => {
      let newState = state;
      for (let x in action.payload) {
        newState[x as keyof typeof initialState] = action.payload[x];
      }
      return newState;
    },
    addFavorite: (state, action: PayloadAction<DashBoardItem>) => {
      let newState = state;
      newState.data.push(action.payload);
      return newState;
    },
    removeFavorite: (state, action: PayloadAction<string>) => {
      let newState = state;
      newState.data = newState.data.filter((m) => m._id !== action.payload);
      return newState;
    }
  }
});

export const { addFavorite, removeFavorite, loadFavorite } = procurementFavoriteSlice.actions;
export default procurementFavoriteSlice.reducer;
