import React, { useEffect } from 'react';
import TopProcurementTabs from '../components/TopProcurementTabs';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { LoaderX } from 'components/shared/Loader';

const Procurement = () => {
  let { data, loading } = useAppSelector((m) => m.procurement);

  return (
    <div className="w-full">
      <Outlet />
    </div>
  );
};

export default Procurement;
