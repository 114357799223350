import React, { useContext, useState } from 'react';
import SuperModal from 'components/shared/SuperModal';
import InputField from 'components/shared/InputField';
import Button from 'components/shared/Button';
import { useFormik } from 'formik';
import { useAppDispatch } from 'store/hooks';
import { addInventory, updateInventory } from 'store/slices/inventorySlice';
import * as yup from 'yup';
import { StoreContext } from 'context';
import { postForm } from 'apis/postForm';
import { displayError, displaySuccess } from 'Utils';
import useInventory from 'Hooks/useInventory';
import { ProcurementType } from '../layout/types';
import { updateProcurement } from 'store/slices/procurementSlice';

interface Props {
  closer: () => void;
  isEditing?: boolean;
  value?: ProcurementType;
}

const initialValue = {
  name: ''
};

const convertRecord = (val: ProcurementType) => {
  return { name: val.name };
};

const ProcurementModal = ({ closer, isEditing, value }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { selectedData } = useContext(StoreContext);
  const { getRecords } = useInventory();

  let { errors, values, handleChange, handleSubmit, touched, resetForm, isValid } = useFormik({
    initialValues: value ? convertRecord(value) : initialValue,
    onSubmit: (data) => {
      let _data: ProcurementType & { _id?: string } = { ...data } as ProcurementType;
      if (!_data?._id) {
        _data._id = Math.random().toString();
      }
      if (isEditing) {
        _edit(_data);
      } else _submit(_data);
    },
    validationSchema: yup.object({
      name: yup.string().required('Please enter a name for the inventory')
    }),
    validateOnBlur: true
  });

  const _submit = async (data: Omit<ProcurementType, '_id'> & { _id?: string }) => {
    setLoading(true);
    let _id = data._id;
    if (!isEditing) {
      delete data?._id;
    }

    const payload = {
      name: data.name,
      project: selectedData._id
    };

    const { e, response } = await postForm(
      'post',
      `procurements/inventory/add
`,
      payload
    );
    if (response) {
      displaySuccess('Inventory added successfully');
      dispatch(addInventory(response.data.data));
      resetForm();
      closer();
    } else displayError(e?.message || '');

    setLoading(false);
  };

  const _edit = async (data: Omit<ProcurementType, '_id'> & { _id?: string }) => {
    setLoading(true);
    const payload = {
      selectedDashboardId: value?._id,
      name: data.name,
      projectId: selectedData._id
    };

    const { e, response } = await postForm('post', `procurements/initialize-dashboard`, payload);
    if (response) {
      displaySuccess('Procurement updated successfully');
      dispatch(updateProcurement({ ...response.data.data }));
      resetForm();
      closer();
    } else displayError(e?.message || '');
    setLoading(false);
  };
  return (
    <SuperModal
      classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={closer}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white rounded-md p-6 mt-20 w-1/2 max-w-[500px] ">
        <div className="flex items-center justify-between">
          <p className=" text-xl font-semibold">{'Edit Procurement'}</p>

          <span className=" cursor-pointer text-sm text-bash" onClick={closer}>
            Close
          </span>
        </div>

        <InputField
          error={(touched?.name && errors?.name) || ''}
          name="name"
          value={values.name}
          onChange={handleChange}
          label="Name"
          placeholder="name"
          className=" !text-bblack-1 "
        />

        <div className=" flex justify-end gap-x-4">
          <Button onClick={closer} type="secondary" text="Cancel" />
          <Button
            isLoading={loading}
            onClick={() => {
              handleSubmit();
            }}
            text={isEditing ? 'Save Changes' : 'Add Inventory'}
          />
        </div>
      </div>
    </SuperModal>
  );
};
export default ProcurementModal;
