import React, { useState } from 'react';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import { FavoriteCard } from './FavoriteItem';
import { DashBoardItem } from '../../layout/types';
import { hoverFade } from 'constants/globalStyles';

interface Props {
  favorites: DashBoardItem[];
}

export const FavoritesSection: React.FC<Props> = ({ favorites }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const cardWidth = 280;

  const handlePrev = () => {
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
    }
  };

  const handleNext = () => {
    if (slideIndex < favorites.length - 1) {
      setSlideIndex(slideIndex + 1);
    }
  };

  return (
    <div className="flex flex-col gap-3 px-6">
      <div className="flex items-center justify-between">
        <p className="font-medium font-satoshi text-sm">Favorites</p>
        <div className="flex items-center">
          <TbChevronLeft
            onClick={handlePrev}
            className={hoverFade}
            size={24}
            color={slideIndex === 0 ? '#c8cdd4' : '#5E6777'}
          />
          <TbChevronRight
            onClick={handleNext}
            className={hoverFade}
            size={24}
            color={slideIndex === favorites.length - 1 ? '#c8cdd4' : '#5E6777'}
          />
        </div>
      </div>
      <div className="flex gap-4 overflow-hidden">
        <div
          className="flex gap-3 transition-transform"
          style={{ marginLeft: `-${slideIndex * cardWidth}px` }}>
          {favorites.map((favorite) => (
            <FavoriteCard item={favorite} key={favorite._id} />
          ))}
        </div>
      </div>
    </div>
  );
};
