import { FC, useEffect, useState } from 'react';
import { DashBoardItem } from '../../layout/types';

interface Props {
  item: DashBoardItem;
}

export const DashboardBarChart: FC<Props> = ({ item }) => {
  const [animate, setAnimate] = useState(false);

  const { budget = 0, purchase = 0, inStock = 0 } = item;
  const data = [
    { label: 'Budget', value: budget, color: '#ECF2FB' },
    { label: 'Purchased', value: purchase, color: '#E9F6F2' },
    { label: 'In stock', value: inStock, color: '#F6E8ED' }
  ];

  const maxValue = Math.max(...data.map((d) => d.value));

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div className="flex flex-col justify-center gap-4 w-full lg:w-[50%] p-4 rounded-md bg-white font-satoshi font-medium text-bblack-1">
      <p className="text-base">Budget breakdown</p>
      <div className="space-y-3">
        {data.map((dataItem) => (
          <div key={dataItem.label} className="flex items-center justify-between gap-4">
            <div
              className={`pl-2 flex items-center rounded-sm h-14 transition-all duration-[2000ms] ${
                animate ? 'max-w-full' : 'max-w-0'
              }`}
              style={{
                width: animate ? `${(dataItem.value / maxValue) * 100}%` : '0%',
                backgroundColor: dataItem.color
              }}
              aria-label={`${dataItem.label}: ${dataItem.value}`}>
              <p className="text-sm w-32 whitespace-nowrap">{dataItem.label}</p>
            </div>
            <p className="text-xs">{dataItem.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
