import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SuperModal from 'components/shared/SuperModal';
import { MdOutlineClose } from 'react-icons/md';
import SelectDate from 'components/shared/SelectDate';
import TaskDropdown from './TaskDropdown';
import { PMStoreContext } from '../../Context';
import useFetch from 'Hooks/useFetch';
import { importMultipleTasks, getTasksByPOW, importSingleTask } from 'apis/tasks';
import { ProjectTask } from '../../types';
import Button from 'components/shared/Button';
import { displaySuccess } from 'Utils';
import { StoreContext } from 'context';

interface Props {
  closer: () => void;
}

const PowDetailsModal = ({ closer }: Props) => {
  const [date, setDate] = useState(new Date());
  const [selectedRadioTask, setSelectedRadioTask] = useState<ProjectTask | null>(null);
  const navigate = useNavigate();
  const { selectedTasks, selectedProgram, powImporting, setContext, detailsModalType } =
    useContext(PMStoreContext);
  const { isLoading: isImportPowLoading, load: importPowLoad } = useFetch<ProjectTask[]>();
  const { isLoading: isImportTaskLoading, load: importTaskLoad } = useFetch<ProjectTask>();
  const { selectedProjectIndex, data } = useContext(StoreContext);

  const projectId = data[selectedProjectIndex]._id;

  const handleImport = async () => {
    if (!selectedProgram || !powImporting) return;
    importPowLoad(
      importMultipleTasks({ fromPowId: selectedProgram._id, toPowId: powImporting._id })
    ).then(() => {
      getTasksByPOW<ProjectTask[]>(powImporting._id).then((res) => {
        setContext((prev) => ({
          ...prev,
          tasks: {
            ...prev.tasks,
            [powImporting._id]: res.data
          }
        }));
        displaySuccess('Program of works imported');
        closer();
        navigate(`/projects/${projectId}/management`);
      });
    });
  };

  const handleSelectRadioTask = (task: ProjectTask) => {
    setSelectedRadioTask(task);
  };

  const handleTaskImport = async () => {
    if (!selectedProgram || !selectedRadioTask || !powImporting) return;
    importTaskLoad(
      importSingleTask({ fromTaskId: selectedRadioTask._id, toPowId: powImporting._id })
    ).then(() => {
      getTasksByPOW<ProjectTask[]>(powImporting._id).then((res) => {
        setContext((prev) => ({
          ...prev,
          tasks: {
            ...prev.tasks,
            [powImporting._id]: res.data
          }
        }));
        displaySuccess('Task Imported');
        closer();
        navigate(`/projects/${projectId}/management/${powImporting._id}`);
      });
    });
  };

  return (
    <SuperModal
      classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={closer}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex flex-col bg-white rounded-md p-6 mt-20 w-1/2 max-w-[700px]">
        <div className="flex justify-between items-center w-full">
          <p className="text-2xl font-semibold">{selectedProgram?.name} details</p>
          <span onClick={closer} className="cursor-pointer">
            <MdOutlineClose size={24} color="#9099A8" />
          </span>
        </div>
        <div className="flex justify-between items-center w-full">
          <label className="w-1/2">Select date of first task:</label>
          <SelectDate
            className="w-1/2"
            placeholder="select date"
            wrapperClassName=" !border-ashShade-4 "
            minDate={new Date()}
            initialValue={new Date()}
            value={date}
            onChange={(e) => {
              setDate(e);
            }}
          />
        </div>
        <p className="font-semibold">Tasks & Subtasks</p>
        <div className="max-h-[400px] overflow-y-auto">
          {selectedTasks.map((task) => {
            return (
              <TaskDropdown
                key={task._id}
                task={task}
                selectedRadioTask={selectedRadioTask}
                onSelect={handleSelectRadioTask}
              />
            );
          })}
        </div>
        <div className=" flex justify-end gap-x-4  pt-4">
          <button onClick={closer} className="py-2 px-8 border border-ashShade-1 rounded-md">
            Cancel
          </button>
          {detailsModalType === 'Pow' ? (
            <Button
              onClick={handleImport}
              isLoading={isImportPowLoading}
              text="Use Program"
              type={date ? 'primary' : 'muted'}
            />
          ) : (
            <Button
              text="Import Tasks"
              type={selectedRadioTask ? 'primary' : 'muted'}
              isLoading={isImportTaskLoading}
              onClick={handleTaskImport}
            />
          )}
        </div>
      </div>
    </SuperModal>
  );
};
export default PowDetailsModal;
