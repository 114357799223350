import { displayError } from 'Utils';
import { postForm } from 'apis/postForm';
import { StoreContext } from 'context';
import React, { useContext, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { loadRecords } from 'store/slices/materialScheduleSlice';

const useMaterialSchedule = (wait: boolean = false) => {
  let {
    selectedProject,
    activeProject,
    data: projects,
    selectedProjectIndex
  } = useContext(StoreContext);
  const [data, setData] = useState<any[]>([]);
  const [scheduleMaterials, setScheduleMaterials] = useState<any[]>([]);
  const [scheduleMaterialsId, setScheduleMaterialsId] = useState<string | null>(null);
  let dispatch = useAppDispatch();

  const getRecords = async () => {
    dispatch(loadRecords({ loading: true }));
    let { e, response } = await postForm(
      'get',
      `procurements/material-schedule/list?projectId=${selectedProject?._id}`
    );

    if (response) {
      dispatch(loadRecords({ data: response?.data?.data, loading: false }));
    } else {
      dispatch(loadRecords({ loading: false }));
      displayError(e?.message || 'Could not fetch records');
    }
  };



  useEffect(() => {
    if (!wait) {
      getRecords();
    }
  }, []);


  return {
    getRecords
  };

};

export default useMaterialSchedule;
