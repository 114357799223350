import { useState, useMemo, FC } from 'react';
import { Line } from 'react-chartjs-2';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import { hoverFade } from 'constants/globalStyles';
import { DashBoardItem, GraphData } from '../../layout/types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Props {
  item: DashBoardItem;
}

export const DashboardLineChart: FC<Props> = ({ item }) => {
  const { disbursementsChart = [], returnsChart = [] } = item;

  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(0);

  const sortDataByDate = (data: GraphData[]) =>
    [...data].sort((a, b) => a.year - b.year || a.month - b.month);

  // Find the earliest month and year in the sorted data
  const earliestDate = useMemo(() => {
    const allData = sortDataByDate([...disbursementsChart, ...returnsChart]);
    // return allData[0];
    return allData.length > 0 ? allData[0] : { month: 2, year: new Date().getFullYear() };
  }, [disbursementsChart, returnsChart]);

  // Calculate the start month (previous month of the earliest date)
  const getPreviousMonth = (month: number, year: number) => {
    if (month === 1) {
      return { month: 12, year: year - 1 };
    }
    return { month: month - 1, year };
  };

  const startDate = getPreviousMonth(earliestDate.month, earliestDate.year);

  const labels = useMemo(() => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];

    const { month: startMonth, year: startYear } = startDate;
    return Array.from({ length: 12 }, (_, i) => {
      const monthIndex = (startMonth + i - 1) % 12;
      const year = startYear + Math.floor((startMonth + i - 1) / 12);
      return `${monthNames[monthIndex]}`;
    });
  }, [startDate]);

  const processData = (data: GraphData[]) => {
    const dataByMonth = Array(12).fill(0);
    data.forEach((entry) => {
      const monthDiff = (entry.year - startDate.year) * 12 + (entry.month - startDate.month);
      if (monthDiff >= 0 && monthDiff < 12) {
        dataByMonth[monthDiff] = entry.quantity;
      }
    });
    return dataByMonth;
  };

  const paginatedLabels = labels.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const paginatedDisbursements = processData(disbursementsChart).slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const paginatedReturns = processData(returnsChart).slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const maxPage = Math.ceil(labels.length / itemsPerPage) - 1;

  const chartFont = {
    family: 'satoshi',
    weight: 400,
    size: 10
  };

  const chartData = {
    labels: paginatedLabels,
    datasets: [
      {
        label: 'Used',
        data: paginatedDisbursements,
        borderColor: '#B63434',
        borderWidth: 1,
        backgroundColor: '#B63434',
        fill: true,
        pointRadius: 0
      },
      {
        label: 'Returned',
        data: paginatedReturns,
        borderColor: '#26A87D',
        borderWidth: 1,
        backgroundColor: '#26A87D',
        fill: true,
        pointRadius: 0
      }
    ]
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Consumption per month',
        position: 'top',
        align: 'start',
        color: '#222B34',
        font: {
          size: 16,
          family: 'satoshi',
          weight: 500
        }
      },
      legend: {
        labels: {
          color: '#222B34',
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
          font: chartFont
        }
      },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false
        },
        ticks: {
          color: '#222B34',
          font: chartFont,
          autoSkip: true,
          callback: function (_, index) {
            const label = labels[index];
            const year = startDate.year + Math.floor((startDate.month - 1 + index) / 12);
            return index === 0 || label === 'Jan' ? [`${label}`, `${year}`] : [`${label}`];
          },
          labelOffset: 0
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: '#222B34',
          font: {
            size: 10,
            family: 'satoshi',
            weight: 'normal'
          }
        }
      }
    }
  };

  return (
    <div className="relative flex flex-col w-full lg:w-[50%] rounded-md p-4 bg-white">
      <div className="flex w-full h-full">
        <Line data={chartData} options={options} style={{ width: '100%', height: '100%' }} />
      </div>
      <TbChevronLeft
        onClick={() => currentPage > 0 && setCurrentPage((prev) => Math.max(prev - 1, 0))}
        className={`${hoverFade} absolute left-2 bottom-7 cursor-pointer ${
          currentPage === 0 ? 'text-gray-300' : ''
        }`}
        size={20}
        color={currentPage === 0 ? '#c8cdd4' : '#5E6777'}
      />

      <TbChevronRight
        onClick={() =>
          currentPage < maxPage && setCurrentPage((prev) => Math.min(prev + 1, maxPage))
        }
        className={`${hoverFade} absolute right-0 bottom-7 cursor-pointer ${
          currentPage === maxPage ? 'text-gray-300' : ''
        }`}
        size={20}
        color={currentPage === maxPage ? '#c8cdd4' : '#5E6777'}
      />
    </div>
  );
};
