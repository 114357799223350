import { FC, useEffect, useMemo, useState } from 'react';

import { useAppSelector } from 'store/hooks';
import { CustomTable } from './components/CustomTable';
import { columns } from './constants';
import ProcurementTabs from '../layout/ProcurementTabs';
import { TbAccessible, TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import Button from 'components/shared/Button';
import { BsFunnel } from 'react-icons/bs';
import useProcurement, { useProcurementDetails } from '../layout/useProcurement';
import { useParams } from 'react-router-dom';
import DashboardAccessModal from './components/DashboardAccessModal';
import { isArrayNullOrEmpty } from 'helpers/helper';
import { FavoritesSection } from './components/FavoritesSection';
import NoContent from 'components/projects/photos/NoContent';

export const DashboardPage: FC = () => {
  const { data, loading } = useAppSelector((m) => m.procurement);
  const procurementFavorites = useAppSelector((m) => m.procurementFavorite.data);
  const { procurementId } = useParams();
  let user = useAppSelector((m) => m.user);
  const [showAccess, setShowAccess] = useState(false);

  const { getProcurements } = useProcurement();

  useEffect(() => {
    if (data.length < 1) {
      getProcurements();
    }
  }, [procurementId]);

  const current = useMemo(() => {
    return data.find((m) => m._id === procurementId);
  }, [procurementId, data]);

  let isEditor = false;
  current?.dashboard?.access.map((a) => {
    if (a.userId === user._id && a.accessType === 'editor') {
      isEditor = true;
    }
  });

  const hasAccess = useMemo(() => {
    let access = current?.dashboard?.access?.find((m) => m.userId === user._id);
    return access;
  }, [current]);

  return (
    <div className=" bg-white rounded-b-lg">
      {showAccess && (
        <DashboardAccessModal
          closer={() => {
            setShowAccess(false);
          }}
        />
      )}
      <ProcurementTabs
        buttons={
          <>
            {hasAccess && (
              <div className="relative flex gap-2 pb-2">
                <Button
                  className="border-ashShade-2 border px-0 mx-0"
                  text=""
                  type="plain"
                  style={{
                    padding: '9px 18px'
                  }}
                  LeftIcon={<BsFunnel color="#9099A8" />}
                />
                <Button
                  onClick={() => {
                    setShowAccess(true);
                  }}
                  className="border-ashShade-2 border px-0 mx-0"
                  text=""
                  type="plain"
                  style={{
                    padding: '9px 18px'
                  }}
                  LeftIcon={<TbAccessible color="#9099A8" />}
                />
              </div>
            )}
          </>
        }
      />
      {!hasAccess ? (
        <NoContent title="You do not have access" subtitle="  " />
      ) : (
        <>
          {!isArrayNullOrEmpty(procurementFavorites) && (
            <FavoritesSection favorites={procurementFavorites} />
          )}
          <CustomTable
            data={current?.dashboard.materials || []}
            loading={loading}
            columns={columns}
          />
        </>
      )}
    </div>
  );
};
