import { FC, useState } from 'react';
import { DashBoardItem } from '../../layout/types';
import { FaEllipsisVertical } from 'react-icons/fa6';
import { DashboardOptionsMenu } from './DashboardOptionMenu';
import { formatWithComma } from 'Utils';

interface Props {
  item: DashBoardItem;
}

export const FavoriteCard: FC<Props> = ({ item }) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  return (
    <div className="flex justify-between bg-ashShade-14 w-[280px] p-4 rounded-md">
      <div className="flex flex-col gap-4 font-satoshi">
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-lg text-bblack-1">{item.material}</p>
          <p className="font-normal text-sm text-bash">
            {formatWithComma(item.inStock)} {item.unit} <span className="text-bblue">In stock</span>
          </p>
        </div>
        <div className="flex gap-10 font-medium text-xs">
          <div>
            <p className="text-bblack-1">Budget</p>
            <p className="text-bash">
              {formatWithComma(item.budget)} {item.unit}
            </p>
          </div>
          <div>
            <p className="text-bblack-1">Purchase</p>
            <p className="text-bash">
              {formatWithComma(item.purchase)} {item.unit}
            </p>
          </div>
        </div>
      </div>
      <div className="relative">
        <span
          onClick={() => {
            setOptionsVisible(true);
          }}
          className=" p-2 w-8 h-8 flex rounded-full  hover:bg-ashShade-0 ">
          <FaEllipsisVertical />
        </span>

        {optionsVisible && (
          <DashboardOptionsMenu
            onClose={() => setOptionsVisible(false)}
            isVisible={optionsVisible}
            item={item}
          />
        )}
      </div>
    </div>
  );
};
