import { FC, useMemo, useState } from 'react';

import PaginationComponent from 'components/projects/financials/book-keeping/PaginationComponent';
import NoContent from 'components/projects/photos/NoContent';
import { LoaderX } from 'components/shared/Loader';
import { isArrayNullOrEmpty } from 'helpers/helper';
import { DashboardTableItem } from './DashboardTableItem';
import { DashBoardItem } from '../../layout/types';
import { TbRefresh } from 'react-icons/tb';
import { hoverFade } from 'constants/globalStyles';
import { ResetThresholdModal } from './ResetThresholdModal';
import { DashboardBarChart } from './DashboardBarChart';
import { DashboardLineChart } from './DashboardLineChart';
import { Tooltip } from 'react-tooltip';

interface Props {
  data: DashBoardItem[];
  loading?: boolean;
  columns: any[];
}
const NUMBER_PER_PAGE = 12;

export const CustomTable: FC<Props> = ({ data, loading, columns }) => {
  let numberOfPages = Math.ceil(data.length / NUMBER_PER_PAGE);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const paginatedData = useMemo(() => {
    return data.slice(currentPage * NUMBER_PER_PAGE, (currentPage + 1) * NUMBER_PER_PAGE);
  }, [currentPage, data]);

  const showIconCondition = useMemo(() => data.some((item) => item.threshold > 0), [data]);

  if (loading) {
    return (
      <div className="w-full flex rounded-md bg-white items-center justify-center p-10 mt-16">
        <LoaderX color="blue" />
      </div>
    );
  }

  if (isArrayNullOrEmpty(data)) {
    return (
      <NoContent
        subtitle="Keep a record of materials needed\ninto complete the project"
        title="No Material recorded yet"
      />
    );
  }

  const handleItemClick = (id: string) => {
    setSelectedItemId((prev) => (prev === id ? null : id));
  };

  return (
    <>
      <table className="bg-white w-full rounded-md">
        <thead>
          <tr className="border-b font-semibold">
            {columns.map((column, index) => (
              <td
                key={index}
                className={`py-6 font-satoshi font-medium text-sm text-bblack-1 h-[64px] ${
                  index === 0 ? 'pl-5' : ''
                }`}>
                <div className="flex items-center gap-2">
                  {column.name}{' '}
                  {column.showIcon && showIconCondition && (
                    <div className={hoverFade + 'refresh'}>
                      <TbRefresh
                        color="#437ADB"
                        size={16}
                        onClick={() => setIsResetModalOpen(true)}
                      />
                      <Tooltip
                        place="top"
                        anchorSelect=".refresh"
                        style={{
                          backgroundColor: '#24282E',
                          color: '#fff',
                          borderRadius: '8px',
                          fontSize: '14px'
                        }}>
                        Reset Threshold
                      </Tooltip>
                    </div>
                  )}
                </div>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item, index) => (
            <>
              <DashboardTableItem
                key={item._id}
                s_n={currentPage * NUMBER_PER_PAGE + index + 1}
                item={item}
                isLastItem={index === paginatedData.length - 1}
                onClick={() => handleItemClick(item._id)}
              />
              {selectedItemId === item._id && (
                <tr className="bg-ashShade-3">
                  <td colSpan={columns.length + 1} className="p-0">
                    <div className="flex flex-col lg:flex-row gap-5 p-6 w-full ">
                      <DashboardBarChart item={item} />
                      <DashboardLineChart item={item} />
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
      <PaginationComponent
        pageIndex={currentPage}
        pageCount={numberOfPages}
        setPageIndex={setCurrentPage}
      />
      {isResetModalOpen && <ResetThresholdModal onClose={() => setIsResetModalOpen(false)} />}
    </>
  );
};
