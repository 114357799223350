import React, { useMemo, useRef, useState, useEffect } from 'react';
import { InventoryMaterial } from '../types';
import { FaEllipsisVertical } from 'react-icons/fa6';
import { TheOption } from 'pages/projects/Home/Components/ProjectCard';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { useClickOutSideComponent } from 'components/projects/Team/Views/Components/OnScreen';
import { useAppDispatch } from 'store/hooks';
import { removeInventoryMaterial } from 'store/slices/inventorySlice';
import { formatWithComma, displaySuccess, displayError } from 'Utils';
import ItemDetailsCard from './ItemDetailsCard';
import { ActivityType } from '../types';
import { postForm } from 'apis/postForm';
import DeleteModal from './DeleteModal';

export interface Props extends InventoryMaterial {
  s_n: number;
  setEditing: (x: InventoryMaterial) => void;
  activeInventory: string;
  setActivity: (x: ActivityType) => void;
  isEditor: boolean;
}

const handleEditing =
  (
    val: InventoryMaterial,
    setter: (x: InventoryMaterial) => void,
    setActivity: (x: ActivityType) => void
  ) =>
  () => {
    setter(val);
    setActivity(val.activityType);
  };
const toggler = (val: any, setter: any) => () => {
  setter(!val);
};

const InventoryTableItem = (m: Props) => {
  const [options, setOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [viewing, setViewing] = useState(false);
  const [top, setTop] = useState(0);
  const optionsRef = useRef<any>();
  useClickOutSideComponent(optionsRef, () => {
    if (!loading) {
      setOptions(false);
    }
  });

  const descriptionRef = useRef<any>();
  useClickOutSideComponent(descriptionRef, () => {
    if (!loading) {
      setOptions(false);
    }
  });

  const hoverRef = useRef<HTMLTableCellElement>(null);
  useClickOutSideComponent(optionsRef, () => {
    if (!loading) {
      setOptions(false);
    }
  });

  let dispatch = useAppDispatch();

  const handleDelete = async () => {
    setLoading(true);
    let { e, response } = await postForm('patch', `procurements/inventory/delete-material`, {
      inventoryId: m.activeInventory,
      materialId: m._id
    });
    if (response) {
      displaySuccess('Record removed Successfully');
      dispatch(removeInventoryMaterial({ inventoryId: m.activeInventory, materialId: m._id }));
    } else {
      displayError(e?.message || 'Could not remove record');
    }
    setLoading(false);
  };

  const handleDeleteSubmit = () => {
    setLoading(true);
    handleDelete();
    setShowDeleteModal(false);
    setLoading(false);
  };

  const pureVal = useMemo(() => {
    let newVal: { [key: string]: any } = {};
    for (let x in m) {
      if (!['s_n', 'setEditing'].includes(x)) {
        newVal[x] = m[x as keyof InventoryMaterial];
      }
    }
    return newVal as InventoryMaterial;
  }, [m]);

  const toggle = () => {
    if (!viewing) {
      setShowDetails(!showDetails);
      setViewing(true);
    }
  };

  const handleViewing = () => {
    setViewing(true);
  };
  const handleViewingLeave = () => {
    setViewing(false);
    setShowDetails(false);
  };

  useEffect(() => {
    if (viewing || showDetails) {
      let details = descriptionRef?.current?.getBoundingClientRect();
      let screenHeight = window.innerHeight;
      let elementTop = hoverRef?.current?.getBoundingClientRect()?.top;
      let newTop = 0;
      if (details && elementTop && details.height >= screenHeight) {
        newTop = -details.top;
      } else if (details && elementTop + details.height > screenHeight) {
        newTop = screenHeight - (details.height + elementTop);
      }
      setTop(newTop);
    }
  }, [viewing, showDetails]);

  return (
    <>
      {showDeleteModal && (
        <DeleteModal
          title="Delete Record"
          deleteWhat="record"
          buttonText="Delete Record"
          closer={() => setShowDeleteModal(false)}
          handleDeleteSubmit={handleDeleteSubmit}
        />
      )}

      <tr className=" py-4 text-bash text-sm w-full ">
        <td className=" pl-4">{m.s_n}</td>
        <td className="py-2">{m.material}</td>
        <td className="py-2">
          {formatWithComma(m.quantity)} {m.unit}
        </td>
        <td className="py-2">{m.workArea}</td>
        <td className={`py-2 `}>
          <span
            className={`py-1 px-3 rounded-3xl text-xs ${
              m.activityType === 'disburse'
                ? 'bg-redShades-1 text-redShades-2'
                : 'bg-bgreen-1 text-bgreen-0'
            }`}>
            {m.activityType.charAt(0).toUpperCase() + m.activityType.slice(1)}
          </span>
        </td>
        <td className="py-2">{new Date(m.date).toDateString().slice(4)}</td>
        <td
          className="py-2 relative hover:cursor-pointer"
          ref={hoverRef}
          onMouseOver={toggle}
          onMouseLeave={toggle}>
          <span className=" text-bash underline font-medium hover:text-bblack-1">View details</span>
          {showDetails && (
            <span
              ref={descriptionRef}
              className=" absolute z-50 bg-white p-4 text-sm w-[458px] right-0 rounded-md text-black shadow-[0_4px_6px_8px_rgba(0,0,0,0.1)]"
              onMouseOver={() => {
                setViewing(true);
              }}
              onMouseLeave={handleViewingLeave}
              style={{ top }}>
              <ItemDetailsCard itemDetails={m} setShowDetails={setShowDetails} />
            </span>
          )}
        </td>
        {m.isEditor && (
          <td className="py-2 relative ">
            <div>
              <span
                onClick={() => {
                  setOptions(true);
                }}
                className=" p-2 w-8 h-8 flex rounded-full  hover:bg-ashShade-0 ">
                <FaEllipsisVertical />
              </span>

              {options && (
                <div
                  ref={optionsRef}
                  className=" top-0 z-10 right-2 absolute bg-white   rounded-md shadow-bnkle lg:w-[136px] ">
                  <TheOption
                    icon={TbEdit}
                    text="Edit"
                    onClick={handleEditing(pureVal, m.setEditing, m.setActivity)}
                    className=" px-4"
                  />
                  <TheOption
                    loading={loading}
                    className=" hover:text-redShades-2 text-redShades-2 hover:bg-redShades-1 px-4"
                    iconColor="#B63434"
                    iconHoveredColor="#B63434"
                    icon={TbTrash}
                    text="Delete"
                    onClick={() => setShowDeleteModal(true)}
                  />
                </div>
              )}
            </div>
          </td>
        )}
      </tr>
    </>
  );
};
export default InventoryTableItem;
