import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ProcurementType } from 'components/projects/procurement/layout/types';

export interface ProcurementStore {
  loading?: boolean;
  data: ProcurementType[];
  activeBook?: string;
}

let initialState: ProcurementStore = {
  loading: false,
  data: [],
  activeBook: ''
};

export const procurementSlice = createSlice({
  name: 'procurement',
  initialState,
  reducers: {
    loadProcurements: (state, action: PayloadAction<Partial<ProcurementStore>>) => {
      let newState: any = state;
      for (let x in action.payload) {
        newState[x] = action.payload[x as keyof ProcurementStore];
      }
      return newState;
    },
    addProcurement: (state, action) => {
      let newState = state;
      newState.data.push(action.payload);
      return newState;
    },
    updateProcurement: (state, action) => {
      let newState = state;
      newState.data = newState.data.map((m) => {
        if (m._id === action.payload._id) {
          return action.payload;
        }
        return m;
      });
      return newState;
    },
    removeProcurement: (state, action) => {
      let newState = state;
      newState.data = newState.data.filter((m) => m._id !== action.payload);
      return newState;
    }
  }
});

export const { loadProcurements, updateProcurement, removeProcurement, addProcurement } =
  procurementSlice.actions;

export const procurement = (state: RootState) => state.procurement;
export default procurementSlice.reducer;
