import Button from 'components/shared/Button';
import { useEffect, useState, useMemo, useContext } from 'react';
import { TbPlus } from 'react-icons/tb';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import SupplyTable from './SupplyTable';
import { Material, SupplyMaterialRecord } from './types';
import ProcurementTabs from '../layout/ProcurementTabs';
import { BsFunnel } from 'react-icons/bs';
import { TbAccessible } from 'react-icons/tb';
import AddSupplyRecord from './components/SupplyHeader';
import Filter from './Filter';
import { SupplyTableCard } from './components/SupplyTableCard';
import { HiOutlineCash } from 'react-icons/hi';
import { TbListNumbers } from 'react-icons/tb';
import { formatWithComma } from 'Utils';
import { StoreContext } from 'context';
import useSupply from 'Hooks/useSupply';
import TabsSupply from './components/TabsSupply';
import NoContent from 'components/projects/photos/NoContent';
import { TOption } from 'components/shared/SelectField/SelectField';
import AddSupplyModal from './AddSupplyModal';
import SupplyAccessModal from './SupplyAccessModal';
import { uniqBy } from 'lodash';
import { loadRecords } from 'store/slices/supplySlice';
import { MaterialTableCard } from '../material-schedule/components/MaterialTableCard';

const Supply = () => {
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showAddMaterial, setShowAddMaterial] = useState(false);
  const [showAccessModal, setShowAccessModal] = useState(false);

  const [editing, setEditing] = useState<Material | undefined>(undefined);

  const [filters, setFilters] = useState<{ [key: string]: (Date | string)[] }>({
    material: [],
    vendor: [],
    date: []
  });
  const { getRecords, getSupply } = useSupply();
  let { selectedProject } = useContext(StoreContext);
  let { data, loading, activeSupply } = useAppSelector((m) => m.supply);
  let user = useAppSelector((m) => m.user);
  const dispatch = useAppDispatch();

  let current = useMemo(() => {
    return data.find((m) => m._id === activeSupply);
  }, [data, activeSupply]);

  const hasAccess = useMemo(() => {
    let access = current?.access?.find((m) => m.userId === user._id);
    return access;
  }, [current]);

  const list = useMemo(() => {
    return current?.materials;
  }, [current]);

  const answer = data?.find((item, index) => item?._id === activeSupply);

  useEffect(() => {
    if (data.length < 1) {
      getRecords();
    }
  }, []);

  const onSupplyClick = (supply: SupplyMaterialRecord) => {
    dispatch(loadRecords({ activeSupply: supply._id }));
  };

  const handleEditClick = (material: Material) => {
    setEditing(material);
    setShowModal(true);
  };

  const toggleAddMaterial = () => {
    setShowAddMaterial((prev) => !prev);
  };
  const capitalizeFirstLetter = (str: string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  let { materialData, vendorData } = useMemo(() => {
    const tempMaterial = answer?.materials.map((item) => ({
      value: capitalizeFirstLetter(item.material),
      icon: '',
      label: capitalizeFirstLetter(item.material)
    }));
    const uniqueMaterialData = uniqBy(tempMaterial, 'value');

    const vendorTemp = answer?.materials.map((item) => ({
      value: capitalizeFirstLetter(item.vendor),
      label: capitalizeFirstLetter(item.vendor),
      icon: ''
    }));
    const uniqueVendorData = uniqBy(vendorTemp, 'value');

    return {
      materialData: uniqueMaterialData,
      vendorData: uniqueVendorData
    };
  }, [answer?.materials]);

  const handleFilter = () => {
    let _data = answer?.materials || [];

    // Apply material filter
    if (filters.material.length > 0) {
      const selectedMaterials = filters.material
        .filter((m): m is string => typeof m === 'string') // Ensure only strings are selected
        .map((m) => m.toLowerCase()); // Normalize to lowercase

      _data = _data.filter((m) => selectedMaterials.includes(m.material?.toLowerCase()));
    }

    // Apply vendor filter
    if (filters.vendor.length > 0) {
      const selectedVendors = filters.vendor
        .filter((v): v is string => typeof v === 'string') // Ensure only strings are selected
        .map((v) => v.toLowerCase()); // Normalize to lowercase

      _data = _data.filter((m) => selectedVendors.includes(m.vendor?.toLowerCase()));
    }

    // Apply date filter
    if (filters.date.length === 2) {
      const [startDate, endDate] = filters.date as Date[];
      const startTime = new Date(startDate).setHours(0, 0, 0, 0); // Set time to 00:00:00
      const endTime = new Date(endDate).setHours(23, 59, 59, 999); // Set time to 23:59:59

      _data = _data.filter((m) => {
        const materialDate = new Date(m.supplyDate).getTime();
        return materialDate >= startTime && materialDate <= endTime;
      });
    }

    // Return empty array if no data matches the filters
    if (_data.length === 0) {
      return [];
    }

    return _data;
  };

  const filtered = useMemo(handleFilter, [filters, list]);

  // const { totalAmount, items } = useMemo(() => {
  const { items } = useMemo(() => {
    // let totalAmount = 0;
    let items = filtered?.length || 0;

    // filtered?.forEach((m) => {
    //   if (m) {
    //     totalAmount += Number(m.amount);
    //   }
    // });

    return {
      // totalAmount,
      items
    };
  }, [filtered]);

  return (
    <>
      {showAccessModal && (
        <SupplyAccessModal
          _id={activeSupply || ''}
          closer={() => {
            setShowAccessModal(false);
          }}
        />
      )}

      <ProcurementTabs
        buttons={
          <>
            {hasAccess && (
              <div className="relative">
                <div className="flex gap-2 pb-2">
                  <Button
                    className={`px-0 mx-0 ${
                      showFilter ? 'bg-[#bfd8ff] border-[#437ADB]' : 'border-ashShade-2'
                    } border`}
                    text=""
                    type="plain"
                    style={{
                      padding: '9px 18px'
                    }}
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                    LeftIcon={<BsFunnel color={showFilter ? '#437ADB' : '#9099A8'} />}
                  />

                  <Button
                    onClick={() => {
                      setShowAccessModal(true);
                    }}
                    className="border-ashShade-2 border px-0 mx-0"
                    text=""
                    type="plain"
                    style={{
                      padding: '9px 18px'
                    }}
                    LeftIcon={<TbAccessible color="#9099A8" />}
                  />
                  <Button
                    onClick={() => {
                      setShowModal(!showModal);
                    }}
                    text=""
                    style={{
                      padding: '9px 18px'
                    }}
                    LeftIcon={<TbPlus color="white" />}
                  />
                </div>
                {/* {showAddMaterial && (
                <AddSupplyRecord
                  onClose={() => setShowAddMaterial(false)}
                  activeTab={activeSupply}
                />
              )} */}
              </div>
            )}
          </>
        }
      />

      {showModal && (
        <AddSupplyModal
          isEditing={Boolean(editing)}
          value={editing}
          closer={() => {
            setShowModal(false);
            setEditing(undefined);
          }}
          activeTab={activeSupply}
        />
      )}

      {!hasAccess ? (
        <NoContent title="You do not have access" subtitle="  " />
      ) : (
        <div className=" bg-white  px-6">
          <div className="">
            <div className="flex items-center justify-between">
              <h2 className="font-Medium text-2xl">Supply</h2>
            </div>
          </div>

          {showFilter && (
            <div className="w-full my-4">
              <Filter
                onChange={(val: { [key: string]: (string | Date)[] }) => {
                  setFilters(val);
                }}
                materialData={materialData as TOption[]}
                vendorData={vendorData as TOption[]}
              />
            </div>
          )}

          {data.length > 0 && (
            <div className="my-[1.2em] flex items-center  w-1/2 justify-between gap-5">
              <MaterialTableCard
                topClassName="!bg-ashShade-16"
                className="bg-[#ECF2FB]"
                icon={<TbListNumbers fontSize={24} color="#437ADB" />}
                label="Number of Items"
                value={`${items} ${items > 0 ? 'items' : 'item'}`}
              />
            </div>
          )}

          {filtered.length > 0 ? (
            <SupplyTable data={filtered} loading={loading} onEdit={handleEditClick} />
          ) : (
            <NoContent
              subtitle="Keep a record of supplies needed to complete the project"
              title="No Supply recorded Yet"
            />
          )}
        </div>
      )}
    </>
  );
};

export default Supply;
