import React, { useContext, useState } from 'react';
import { Inventory } from '../types';
import SuperModal from 'components/shared/SuperModal';
import InputField from 'components/shared/InputField';
import Button from 'components/shared/Button';
import { useFormik } from 'formik';
import { useAppDispatch } from 'store/hooks';
import { addInventory, updateInventory } from 'store/slices/inventorySlice';
import * as yup from 'yup';
import { StoreContext } from 'context';
import { postForm } from 'apis/postForm';
import { displayError, displaySuccess } from 'Utils';
import useInventory from 'Hooks/useInventory';

interface Props {
  closer: () => void;
  isEditing?: boolean;
  value?: Inventory;
}

const initialValue = {
  name: '',
  description: ''
};

const convertRecord = (val: Inventory) => {
  let newVal: { [key: string]: any } = { ...val };
  return newVal as Inventory;
};

const CreateInventoryModal = ({ closer, isEditing, value }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { selectedData } = useContext(StoreContext);
  const { getRecords } = useInventory();

  let { errors, values, handleChange, handleSubmit, touched, resetForm } = useFormik({
    initialValues: value ? convertRecord(value) : initialValue,
    onSubmit: (data) => {
      let _data: Inventory & { _id?: string } = { ...data } as Inventory;
      if (!_data?._id) {
        _data._id = Math.random().toString();
      }

      if (isEditing) {
        _edit(_data);
      } else _submit(_data);
    },
    validationSchema: yup.object({
      name: yup.string().required('Please enter a name for the inventory'),
      description: yup.string().required('Please enter a description')
    }),
    validateOnBlur: true
  });

  const _submit = async (data: Omit<Inventory, '_id'> & { _id?: string }) => {
    setLoading(true);
    let _id = data._id;
    if (!isEditing) {
      delete data?._id;
    }

    const payload = {
      name: data.name,
      description: data.description,
      project: selectedData._id
    };

    const { e, response } = await postForm(
      'post',
      `procurements/inventory/add
`,
      payload
    );
    if (response) {
      displaySuccess('Inventory added successfully');
      dispatch(addInventory(response.data.data));
      resetForm();
      closer();
    } else displayError(e?.message || '');

    setLoading(false);
  };

  const _edit = async (data: Omit<Inventory, '_id'> & { _id?: string }) => {
    setLoading(true);
    let _data: any = { ...data };

    for (let x of [
      '_id',
      'project',
      '__v',
      'createdBy',
      'createdAt',
      'access',
      'status',
      'activeInventory',
      'isEditor'
    ]) {
      delete _data[x];
    }

    let newData;

    if (_data?.materials) {
      newData = _data.materials.map((material: any) => {
        let newMaterial = { ...material };
        newMaterial.materialId = newMaterial._id;
        delete newMaterial._id;
        return newMaterial;
      });
    }
    _data.materials = newData;

    const payload = {
      inventoryId: value?._id,
      ..._data
    };

    const { e, response } = await postForm('patch', `procurements/inventory/update`, payload);
    if (response) {
      displaySuccess('Record updated successfully');
      dispatch(updateInventory({ ...data }));
      resetForm();
      closer();
    } else displayError(e?.message || '');
    setLoading(false);
  };

  return (
    <SuperModal
      classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={closer}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white rounded-md p-6 mt-20 w-1/2 max-w-[500px] ">
        <div className="flex items-center justify-between">
          <p className=" text-xl font-medium">{isEditing ? 'Edit Inventory' : 'Add Inventory'}</p>

          <span className=" cursor-pointer text-sm text-bash" onClick={closer}>
            Close
          </span>
        </div>

        <InputField
          error={(touched?.name && errors?.name) || ''}
          name="name"
          value={values.name}
          onChange={handleChange}
          label="Name"
          placeholder="Inventory 1"
          className=" !text-bblack-1 "
        />

        <InputField
          isTextArea
          error={(touched.description && errors.description) || ''}
          name="description"
          value={values.description}
          onChange={handleChange}
          label="Description"
          placeholder="e.g comments"
          className=" !text-bblack-1 "
        />

        <div className=" flex justify-end gap-x-4">
          <Button onClick={closer} type="secondary" text="Cancel" />
          <Button
            isLoading={loading}
            onClick={() => {
              handleSubmit();
            }}
            text={isEditing ? 'Save Changes' : 'Add Inventory'}
          />
        </div>
      </div>
    </SuperModal>
  );
};
export default CreateInventoryModal;
