import { useState, useContext } from 'react';
import { HiOutlineChevronUp, HiOutlineChevronDown } from 'react-icons/hi';
import type { ProjectTask } from '../../types';
import { PMStoreContext } from '../../Context';

interface Props {
  task: ProjectTask;
  selectedRadioTask: ProjectTask | null;
  onSelect: (task: ProjectTask) => void;
}

const TaskDropdown = ({ task, selectedRadioTask, onSelect }: Props) => {
  const { name, subTasks } = task;
  const [showSubtasks, setShowSubtasks] = useState(true);
  const toggleQuestion = () => {
    setShowSubtasks(!showSubtasks);
  };
  const { detailsModalType } = useContext(PMStoreContext);
  const isSelected = selectedRadioTask?._id === task._id;

  const totalDuration = subTasks.reduce((acc, subtask) => acc + subtask.duration.value, 0);

  const handleRadioChange = () => {
    onSelect(task);
    setShowSubtasks(!showSubtasks);
  };

  return (
    <div className="cursor-pointer">
      <div
        onClick={toggleQuestion}
        className="flex justify-between items-center bg-pbg mb-4 mt-4 px-4 py-1 rounded-md">
        <div className="flex items-center gap-3">
          {detailsModalType === 'Task' && (
            <input type="radio" name="task" checked={isSelected} onChange={handleRadioChange} />
          )}
          <div className="flex items-center gap-3">
            <h3 className="text-lg font-semibold">{name}</h3>
            <span className="bg-[#9099A8] py-1 px-2 text-white text-sm rounded">
              {totalDuration} day{totalDuration > 1 ? 's' : ''}
            </span>
          </div>
        </div>
        {showSubtasks ? (
          <HiOutlineChevronUp size={24} color="#9099A8" />
        ) : (
          <HiOutlineChevronDown size={24} color="#9099A8" />
        )}
      </div>
      <div
        className={`transition-all duration-300 ease-in-out ${
          !showSubtasks ? 'max-h-0  opacity-0' : 'max-h-[500px] opacity-100'
        }
        `}>
        <div className="text-bash font-medium">
          <div className="ml-6">
            <div className="flex flex-col gap-6 border-l-4 border-ashShade-3 pl-6">
              {subTasks.map((subtask) => {
                return (
                  <div key={subtask._id} className="flex items-center gap-3">
                    <p className="font-medium text-sm">{subtask.name}</p>
                    <span className="bg-pbg py-1 px-2 text-bash text-xs rounded">
                      {subtask.duration.value} day{subtask.duration.value > 1 ? 's' : 'day'}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaskDropdown;
