import Button from 'components/shared/Button';
import { TbPlus } from 'react-icons/tb';
import { FC, useState, useEffect, useRef } from 'react';
import { MaterialRecord } from '../types';
import AddMaterialModal from '../AddMaterialModal';
import { TbDownload } from 'react-icons/tb';
import UploadFile from './UploadFile';

interface AddMaterialRecordProps {
  onClose: () => void;
  activeTab: string;
}

const AddMaterialRecord: FC<AddMaterialRecordProps> = ({ onClose, activeTab }) => {
  const [showModal, setShowModal] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [editing, setEditing] = useState<MaterialRecord | undefined>(undefined);
  const modalRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const handleClickOutside= (e: MouseEvent) => {
  //     if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
  //       onClose();
  //     }
  //   }

  //   document.addEventListener('mousedown', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //   };
  // }, [onClose]);

  const handleAddMaterialClick = () => {
    // onClose();
    setShowModal(true);
    // setTimeout(() => onClose(), 5000);
  };

  const handleUploadMaterialClick = () => {
    // onClose();
    setShowUpload(true);
  };

  return (
    <div>
      {showModal && (
        <AddMaterialModal
          isEditing={Boolean(editing)}
          value={editing}
          closer={() => {
            setShowModal(false);
            setEditing(undefined);
          }}
          activeTab={activeTab}
        />
      )}

      {showUpload && (
        <UploadFile
          closer={() => {
            setShowUpload(false);
          }}
          activeTab={activeTab}
        />
      )}

      <div ref={modalRef} className="absolute w-full mt-2 bg-white rounded-md shadow-md">
        <Button
          text="Upload Excel File"
          onClick={() => {
            handleUploadMaterialClick();
          }}
          type="transparent"
          className="group flex items-center hover:rounded-none px-0 mx-0 gap-2"
          style={{
            width: '100%',
            padding: '8px 0px 8px 8px',
            border: 'none',
            borderRadius: 'none'
          }}
          textStyle="text-bash group-hover:text-[#437ADB] transition-colors duration-300 flex-1 text-left"
          LeftIcon={
            <TbDownload className="rotate-180 text-[#9099A8] group-hover:text-[#437ADB] transition-colors duration-300" />
          }
        />
        <Button
          text="Add Material"
          onClick={() => {
            handleAddMaterialClick();
          }}
          type="transparent"
          className="group flex items-center hover:rounded-none px-0 mx-0 gap-2"
          style={{
            width: '100%',
            padding: '8px 0px 8px 8px',
            border: 'none',
            borderRadius: 'none'
          }}
          textStyle="text-bash group-hover:text-[#437ADB] transition-colors duration-300 flex-1 text-left"
          LeftIcon={
            <TbPlus className="rotate-180 text-[#9099A8] group-hover:text-[#437ADB] transition-colors duration-300" />
          }
        />
      </div>
    </div>
  );
};

export default AddMaterialRecord;
