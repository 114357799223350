export const powTemplates = [
  {
    _id: 'pow-a',
    name: 'Foundation',
    numOfRooms: 5,
    buildingType: 'Duplex',
    tasks: [
      {
        _id: 110,
        taskName: 'Groundwork',
        subTasks: [
          { _id: 'sub1-1-a', name: 'Excavation', duration: 10 },
          { _id: 'sub1-1-b', name: 'Grading', duration: 7 },
          { _id: 'sub1-1-c', name: 'Filling Dirt', duration: 5 },
          { _id: 'sub1-1-d', name: 'Compaction', duration: 4 }
        ]
      },
      {
        _id: 111,
        taskName: 'Reinforcement',
        subTasks: [
          { _id: 'sub1-2-a', name: 'Install Rebars', duration: 6 },
          { _id: 'sub1-2-b', name: 'Pour Concrete', duration: 4 },
          { _id: 'sub1-2-c', name: 'Curing', duration: 5 },
          { _id: 'sub1-2-d', name: 'Shuttering Removal', duration: 2 }
        ]
      }
    ]
  },
  {
    _id: 'pow-b',
    name: 'Framing',
    numOfRooms: 4,
    buildingType: 'Duplex',
    tasks: [
      {
        _id: 112,
        taskName: 'Wall Framing',
        subTasks: [
          { _id: 'sub1-3-a', name: 'Stud Placement', duration: 5 },
          { _id: 'sub1-3-b', name: 'Sheathing', duration: 3 },
          { _id: 'sub1-3-c', name: 'Window Cutouts', duration: 2 },
          { _id: 'sub1-3-d', name: 'Door Cutouts', duration: 3 }
        ]
      },
      {
        _id: 113,
        taskName: 'Roof Framing',
        subTasks: [
          { _id: 'sub1-4-a', name: 'Install Trusses', duration: 4 },
          { _id: 'sub1-4-b', name: 'Decking', duration: 3 },
          { _id: 'sub1-4-c', name: 'Roof Shingles', duration: 5 },
          { _id: 'sub1-4-d', name: 'Install Gutter', duration: 2 }
        ]
      }
    ]
  },
  {
    _id: 'pow-c',
    name: 'Electrical Work',
    numOfRooms: 7,
    buildingType: 'Duplex',
    tasks: [
      {
        _id: 114,
        taskName: 'Wiring',
        subTasks: [
          { _id: 'sub1-5-a', name: 'Running Conduit', duration: 4 },
          { _id: 'sub1-5-b', name: 'Installing Outlets', duration: 3 },
          { _id: 'sub1-5-c', name: 'Breaker Installation', duration: 2 },
          { _id: 'sub1-5-d', name: 'Lighting Setup', duration: 4 }
        ]
      },
      {
        _id: 115,
        taskName: 'Panel Installation',
        subTasks: [
          { _id: 'sub1-6-a', name: 'Main Panel Setup', duration: 3 },
          { _id: 'sub1-6-b', name: 'Circuit Breaker Testing', duration: 2 },
          { _id: 'sub1-6-c', name: 'Wiring Inspection', duration: 2 },
          { _id: 'sub1-6-d', name: 'Final Connection', duration: 2 }
        ]
      }
    ]
  },
  {
    _id: 'pow-d',
    name: 'Plumbing',
    numOfRooms: 5,
    buildingType: 'Duplex',
    tasks: [
      {
        _id: 116,
        taskName: 'Pipe Installation',
        subTasks: [
          { _id: 'sub1-7-a', name: 'Main Line Installation', duration: 4 },
          { _id: 'sub1-7-b', name: 'Valve Installation', duration: 3 },
          { _id: 'sub1-7-c', name: 'Testing for Leaks', duration: 2 },
          { _id: 'sub1-7-d', name: 'Shut-off Valve Setup', duration: 1 }
        ]
      },
      {
        _id: 117,
        taskName: 'Fixtures Installation',
        subTasks: [
          { _id: 'sub1-8-a', name: 'Toilet Setup', duration: 2 },
          { _id: 'sub1-8-b', name: 'Sink Installation', duration: 2 },
          { _id: 'sub1-8-c', name: 'Shower Setup', duration: 3 },
          { _id: 'sub1-8-d', name: 'Faucet Installation', duration: 2 }
        ]
      }
    ]
  },
  {
    _id: 'pow-e',
    name: 'Foundation',
    numOfRooms: 6,
    buildingType: 'Duplex',
    tasks: [
      {
        _id: 118,
        taskName: 'Excavation',
        subTasks: [
          { _id: 'sub2-1-a', name: 'Digging Trenches', duration: 5 },
          { _id: 'sub2-1-b', name: 'Leveling', duration: 3 },
          { _id: 'sub2-1-c', name: 'Piling', duration: 4 },
          { _id: 'sub2-1-d', name: 'Shuttering', duration: 4 }
        ]
      },
      {
        _id: 119,
        taskName: 'Foundation Pouring',
        subTasks: [
          { _id: 'sub2-2-a', name: 'Reinforcement Setup', duration: 5 },
          { _id: 'sub2-2-b', name: 'Pouring Concrete', duration: 3 },
          { _id: 'sub2-2-c', name: 'Curing', duration: 6 },
          { _id: 'sub2-2-d', name: 'Removing Shuttering', duration: 2 }
        ]
      }
    ]
  },
  {
    _id: 'pow-f',
    name: 'Framing',
    numOfRooms: 5,
    buildingType: 'Duplex',
    tasks: [
      {
        _id: 120,
        taskName: 'Wall Framing',
        subTasks: [
          { _id: 'sub2-3-a', name: 'Stud Installation', duration: 4 },
          { _id: 'sub2-3-b', name: 'Sheathing', duration: 3 },
          { _id: 'sub2-3-c', name: 'Window Frames', duration: 2 },
          { _id: 'sub2-3-d', name: 'Door Frames', duration: 2 }
        ]
      },
      {
        _id: 121,
        taskName: 'Roof Framing',
        subTasks: [
          { _id: 'sub2-4-a', name: 'Truss Installation', duration: 5 },
          { _id: 'sub2-4-b', name: 'Roof Decking', duration: 3 },
          { _id: 'sub2-4-c', name: 'Shingling', duration: 4 },
          { _id: 'sub2-4-d', name: 'Gutter Installation', duration: 3 }
        ]
      }
    ]
  }
];

export const workDaysData = [
  { _id: 1, day: 'Sunday', selected: true },
  { _id: 2, day: 'Monday', selected: true },
  { _id: 3, day: 'Tuesday', selected: true },
  { _id: 4, day: 'Wednesday', selected: true },
  { _id: 5, day: 'Thursday', selected: true },
  { _id: 6, day: 'Friday', selected: true },
  { _id: 7, day: 'Saturday', selected: true }
];
