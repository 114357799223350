import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

import SuperModal from 'components/shared/SuperModal';
import Button from 'components/shared/Button';
import { StoreContext } from 'context';
import { displayError, displaySuccess } from 'Utils';

import CloudUpload from 'assets/cloud-add.png';
import ExcelSheet from 'assets/ExcelSheet.png';
import InputField from 'components/shared/InputField';
import { handleDownload } from 'components/shared/utils';
import useMaterialSchedule from 'Hooks/useMaterialSchedule';

interface Props {
  closer: () => void;
  activeTab: string;
}

const UploadFile: FC<Props> = ({ closer, activeTab }) => {
  const [file, setFile] = useState<File | null>(null);
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);

  let { activeProject } = useContext(StoreContext);
  const { getRecords } = useMaterialSchedule(true);

  const validationSchema = yup.object().shape({
    scheduleName: yup.string(),
    file: yup.mixed().required('Please select a file')
  });

  let { errors, values, handleChange, touched, resetForm, submitForm, setFieldValue } = useFormik({
    initialValues: {
      scheduleName: '',
      file: null
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleFileUpload(values.file);
    }
  });

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    setFile(selectedFile);
    setIsFileSelected(true);
  };

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
    setFieldValue('file', selectedFile);
    setIsFileSelected(true);
  };

  const handleRemoveFile = () => {
    if (inputRef.current) inputRef.current.value = '';
    setFile(null);
    setIsFileSelected(false);
    resetForm();
    setProgress(0);
  };

  const handleFileUpload = async (file: File | null) => {
    if (!file) {
      displayError('Please select a file');
      return;
    }

    let form = new FormData();
    form.append('file', file);
    form.append('project', activeProject._id);

    if (values.scheduleName.trim() !== '') {
      form.append('name', values.scheduleName);
      form.append('scheduleId', '');
    } else {
      form.append('name', '');
      form.append('scheduleId', activeTab);
    }

    let token = localStorage.getItem('token');

    try {
      setIsLoading(true);

      await axios.post(
        `${process.env.REACT_APP_API_PROJECTS}procurements/material-schedule/upload-excel`,
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
          }
        }
      );

      handleRemoveFile();
      displaySuccess('Record added successfully');
      await getRecords();
      resetForm();

      closer();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      displayError("Couldn't upload file. Please try again.");
      setProgress(0);
    }
  };

  const handleDownloadExampleFile = () => {
    handleDownload(
      'https://docs.google.com/spreadsheets/d/1dhKNtDQVTa6zF226lWrpgMDrwzmX-i2-/export?format=xlsx',
      'Material-Schedule-Template'
    );
  };

  return (
    <div>
      <SuperModal
        classes="bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
        closer={closer}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className=" bg-white rounded-md p-6 mt-20 mb-10 w-1/2 max-w-[575px] ">
          <div className="flex items-center justify-between mb-2">
            <p className=" text-xl font-medium text-black">Upload Excel File</p>

            <span className=" cursor-pointer text-sm text-bash" onClick={closer}>
              <CgClose fontSize={24} color="#9099A8" />
            </span>
          </div>

          <p className="text-base font-medium font-satoshi">
            <span className="text-bash">Ensure your columns are arranged properly </span>
            <button
              onClick={handleDownloadExampleFile}
              className="text-bblue hover:underline decoration-bblue">
              Download our example
            </button>
          </p>

          {!isFileSelected ? (
            <div
              className="mt-5 upload-box border-2 border-ashShade-2 border-dashed rounded-md h-[208px] flex items-center justify-center"
              onDrop={handleFileDrop}
              onDragOver={(e) => e.preventDefault()}>
              <div className="upload-placeholder">
                <img
                  src={CloudUpload}
                  alt="cloud upload"
                  className="mx-auto mb-2 h-[84px] w-[84px]"
                />

                <p className="font-Medium">
                  <span className="text-bash ">Drag and Drop to upload or </span>
                  <label
                    htmlFor="file-input"
                    className="text-bblue underline decoration-bblue hover:cursor-pointer">
                    Browse
                  </label>
                </p>
                <input
                  ref={inputRef}
                  id="file-input"
                  type="file"
                  onChange={handleFileSelection}
                  accept=".xlsx, .xls"
                  style={{ display: 'none' }}
                />
              </div>
            </div>
          ) : (
            <div>
              <InputField
                error={(touched?.scheduleName && errors?.scheduleName) || ''}
                name="scheduleName"
                value={values.scheduleName}
                onChange={handleChange}
                label="Schedule Name (Optional)"
                placeholder="Enter schedule name"
                className=" !text-bblack-1"
              />
            </div>
          )}
          {file && (
            <div className="border border-pbg rounded-md mt-6 h-[88px] max-h-[88px] flex items-center px-4">
              <div className="w-full">
                <div className="flex justify-between items-center">
                  <p className="flex items-center gap-3">
                    <span>
                      <img src={ExcelSheet} alt="Excel Sheet Icon" className="w-[32px] h-[32px]" />
                    </span>
                    <p className="flex flex-col gap-1">
                      <span className="text-sm text-bblack-1 font-Medium">{file.name}</span>
                      <span className="text-bash text-xs font-Medium">{file.size / 1000} KB</span>
                    </p>
                  </p>
                  <button onClick={handleRemoveFile}>
                    <CgClose fontSize={16} color="#9099A8" />
                  </button>
                </div>
                <div className="w-full h-[4px] mt-2 rounded-full flex items-center bg-ashShade-3">
                  <p
                    style={{ width: `${progress}%` }}
                    className="h-[4px] bg-bblue rounded-full"></p>
                  <p
                    style={{ width: `${100 - progress}%` }}
                    className="h-[4px] bg-ashShade-3 rounded-r-full"></p>
                </div>
              </div>
            </div>
          )}
          <div className="modal-actions mt-6 flex justify-end gap-x-4">
            <Button onClick={closer} type="secondary" text="Cancel" />
            <Button
              text={isLoading ? 'Uploading' : 'Import file'}
              onClick={submitForm}
              disabled={isLoading}
            />
          </div>
        </div>
      </SuperModal>
    </div>
  );
};

export default UploadFile;
