import { useState, useContext } from 'react';
import SuperModal from 'components/shared/SuperModal';
import { MdOutlineClose } from 'react-icons/md';
import { workDaysData } from '../utils/constants';
import Button from 'components/shared/Button';
import useRole from 'Hooks/useRole';
import { updateDaysOff, getOnePow } from 'apis/pow';
import useFetch from 'Hooks/useFetch';
import type { POW } from '../../types';
import { displaySuccess } from 'Utils';
import { PMStoreContext } from '../../Context';

interface WorkDay {
  _id: number;
  day: string;
  selected: boolean;
}

interface Props {
  closer: () => void;
  pow: POW;
}

const WorkDaysModal = ({ closer, pow }: Props) => {
  const { isProfessional } = useRole();
  const { setContext } = useContext(PMStoreContext);
  const workDays = workDaysData.map((day) => {
    return pow.offDays.includes(day.day) ? { ...day, selected: false } : { ...day, selected: true };
  });

  const [days, setDays] = useState<WorkDay[]>(workDays);
  const { isLoading: isUpdateLoading, load: updateLoader } = useFetch();
  const selectedDaysCount = days.filter((day) => day.selected).length;

  const handleSelection = (id: number) => {
    setDays((prev) => {
      return prev.map((day) => {
        if (day._id === id) {
          return { ...day, selected: !day.selected };
        }
        return day;
      });
    });
  };

  const updateContext = () => {
    getOnePow<POW[]>(pow._id).then((res) => {
      setContext((prev) => ({
        ...prev,
        activePow: res.data.find((one) => one._id === pow._id)
      }));
    });
  };

  const daysOff = days.filter((day) => !day.selected).map((day) => day.day);
  const handleOffDaysUpdate = async () => {
    updateLoader(updateDaysOff({ powId: pow._id, offDays: daysOff })).then((res) => {
      displaySuccess('Work days updated');
      updateContext();
      closer();
    });
  };

  return (
    <SuperModal
      classes="bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={closer}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex flex-col bg-white rounded-md p-6 mt-20 w-[450px] max-w-[700px]">
        <div className="flex justify-between items-center mb-6 text-bblack-1">
          <h5 className="text-base">Work days</h5>
          <span onClick={closer} className="cursor-pointer">
            <MdOutlineClose size={24} color="#9099A8" />
          </span>
        </div>
        <p className="text-sm text-bblack-1 mb-4">Select work days</p>
        <div className="flex justify-between items-center mb-8">
          {days.map((workDay) => {
            const { _id, day, selected } = workDay;
            return (
              <button
                key={_id}
                className={`grid place-items-center w-10 h-10 rounded-full ${
                  selected ? 'bg-bblue text-white' : 'bg-[#F1F2F4] text-bash'
                }`}
                onClick={() => handleSelection(_id)}>
                {day.slice(0, 1)}
              </button>
            );
          })}
        </div>
        {isProfessional && (
          <div className="flex justify-between items-center">
            {/* selected work days count */}
            <p className="text-bash text-sm">{selectedDaysCount} selected</p>
            <div className="flex gap-4 items-center">
              <button
                onClick={closer}
                className="py-2 px-8 border text-bblack-1 border-ashShade-4 rounded-md">
                Cancel
              </button>
              <Button
                text="Done"
                type={selectedDaysCount > 0 ? 'primary' : 'muted'}
                onClick={handleOffDaysUpdate}
                isLoading={isUpdateLoading}
              />
            </div>
          </div>
        )}
      </div>
    </SuperModal>
  );
};

export default WorkDaysModal;
